import { Body, Button, Popover } from "@walmart-web/livingdesign-components";
import { SignOut } from '@walmart-web/livingdesign-icons';
import React, { useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { LOGOUT } from '../../../constants/actionButtons';
import { HelpCenterIcon, User, WalmartSpark } from '../../../constants/images';
import { decryptUserDetail } from '../../../helpers/utils';
import { setLogout } from '../../../store/actions/userActions';
import ThemisTopNavStyles from './ThemisTopNav.styles';
import { ACCOUNT, APP_NAME, HELP_CENTER } from "../../../constants/constants";
import { interval, tknInterval } from "../../../App";
import { Tooltip } from "@mui/material";

const ThemisTopNav = () => {
  const classes = ThemisTopNavStyles();
  const dispatch = useDispatch();
  const history = useHistory()

  const calloutRef = useRef();
  const [isCalloutOpen, setIsCalloutOpen] = useState(false);
  const entityId = localStorage.getItem("entityId");
  const user = localStorage.getItem("userDetails");
  const localMetaData = localStorage.getItem("metadata");


  const userDetails = user && JSON.parse(decryptUserDetail(user, entityId));
  const metadata = localMetaData && JSON.parse(decryptUserDetail(localMetaData, entityId));


  const handleLogout = () => {
    setIsCalloutOpen(false)
    dispatch(setLogout());
    clearInterval(interval);
    clearInterval(tknInterval);
    localStorage.clear();
    history.push('/logout')
  }

  return (
    <div className={classes.userActionsNavBar} data-testid="ThemisTopNav-container">
      <div className={classes.bannerContainer}>
        <WalmartSpark className={classes.spark}></WalmartSpark>
        <div className={classes.text}>{APP_NAME}</div>
        {metadata?.privatePass &&
        <div className={classes.rightMenu}>
          <div className={classes.helpCenterIcon}>
            <Tooltip title={HELP_CENTER}>
             <HelpCenterIcon data-testid="help-center-icon" id="help-center-icon" onClick={() => history.push("/helpCenter")}></HelpCenterIcon>
            </Tooltip>
            </div>
          <Popover
            data-testid="detail-popup"
            content={
              <div className={classes.loggenUserDetailWrap} data-testid="loggedin-user-menu" id="loggedin-user-menu">
                <Body as="p" size="medium" weight={400} className={classes.userName}>
                  {userDetails?.firstName} {userDetails?.lastName}
                </Body>
                <Button leading={<SignOut size="small" />}
                  size="small" variant="tertiary" onClick={handleLogout} id="logout-btn" data-testid="logout-btn">
                  {LOGOUT}
                </Button>
              </div>
            }
            isOpen={isCalloutOpen}
            onClose={() => setIsCalloutOpen(false)}
            position="bottom"
            className={`${classes.rightMenuIcon} ${classes.loggedInPopover}`}
            triggerRef={calloutRef}
          >
            <Tooltip title={ACCOUNT}>
             <User ref={calloutRef} className={classes.userIcon} onClick={() => setIsCalloutOpen(true)} data-testid="loggedin-user-icon" id="loggedin-user-icon"></User>
            </Tooltip>
          </Popover>
        </div>
        }
      </div>
    </div>
  );
};
export default ThemisTopNav;
