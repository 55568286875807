export const APP_NAME = "eWMMS";
const RECENTLY_MODIFIED = 'Recently Modified';
const MY_STARRED = 'My Starred';
const SUB_CREATED_BY = "Created By";
const SUB_XML_FORMAT_PRESENTATION = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
const AMOUNT_PAID = "Amount Paid";
export const NA = "N/A";
export const PRIVACY_POLICY = "Privacy Policy";
export const PRIVACY_POLICY_LINK = 'https://corporate.walmart.com/privacy-security';
export const USER_INACTIVITY_TIME = 900000;
export const SEARCH_CHAR_LIMIT = 48;
export const STATUS_ACTIVE = "active";
export const MIN_CALENDAR_DAYS = '90';
export const SELECT_MATTER_NUMBER = "Select Matter number";
export const SELECT_MATTER_NAME = "Select Matter name";
export const SIDE_NAVIGATION_WIDTH = 230;
export const PEOPLE_DATA = 'people-data';
export const MATTER_DATA = "matter-data";
export const DESCRIPTION = "Description";
export const CREATED_BY = SUB_CREATED_BY;
export const VERSION = 'Version';
export const MATTER = "Matter";
export const DUE_DATE = "Due Date";
export const TAGS = "Tags";
export const SUBJECT = "Subject";
export const MATTER_NAME = 'Matter Name';
export const MATTER_NUMBER = 'Matter Number';
export const MSR_WALMART_ATTORNEY_NAME = "Walmart Attorney Name";
export const MSR_JUSTIFICATION_FOR_RATE = "Justification For Rate";
export const MSR_BREADCRUMB_TITLE = "Matter Specific Rate Request";
export const MSR_BREADCRUMB_TITLE_PRACTICE_AREA = "Practice Area Rate Request";
export const MSR_TITLE = "Create Matter Specific Rate";
export const CPR_TITLE = "Create Practice Area Rate";
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';
export const MATTER_DESCRIPTION = "Matter Description";
export const BUDGET_NAME = "Budget Name";
export const BUDGET_AMOUNT = "Budget Amount";
export const FIND_MATTER = "Find a Matter";
export const SENSITIVE = "Sensitive";
export const APPROVED = 'APPROVED';
export const REJECTED = "REJECTED";
export const PAID_DATE_LABEL = 'Paid To Date';
export const CREATE_NEW_VERSION = "Create New Version";
export const CLOSURE_DATE = 'Closure Date';
export const CREATION_DATE = 'Matter Created Date';
export const NEW_NOTE = 'New Note';
export const NOTE_DESCRIPTION_HELPER_TEXT = 'Note description will not be editable after creation';
export const CANCEL_NOTE_CREATION = 'Cancel Note Creation';
export const CANCEL_NOTE_CREATION_TEXT = 'Continuing will not create a note, and any entered content will not be saved.';
export const CREATE_DATE = 'Create Date';
export const INVOICE_DATE = 'Invoice Date';
export const YES = "Yes";
export const NO = 'No';
export const MAKE_PUBLIC_TEXT = 'Make Publicly Available to Outside Counsel';
export const MAKE_PUBLIC_DESCRIPTION = 'Making this note publicly available will allow access by all Outside Counsel assigned to ';
export const DOCUMENT = 'Document';
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const PHONE_NUMBER = "Phone Number";
export const ADDRESS = "Address";
export const EMAIL = "Email";
export const RESET_PASSWORD_CODE = "Reset Password Code";
export const PASSWORD = "Password";
export const NEW_PASSWORD = "New Password";
export const RE_ENTER_PASSWORD = "Re-Enter Password";
export const WEBSITE = "Website";
export const FIRM_EMAIL_ADDRESS = "Firm Email Address";
export const FIRM_PHONE_NO = "Firm Phone Number";
export const FIRM_FAX_NO = "Firm Fax Number";
export const MEL_APPROVED = "MEL Approved";
export const EXPERTISE = "Expertise";
export const PRIMARY_PRACTICE_AREA = "Primary Practice Area";
export const TAX_ID = "Tax ID";
export const BILLING_CONTACT_NAME = "Billing Contact Name";
export const RELATIONSHIP_PARTNER_NAME = "Relationship Partner Name";
export const RELATIONSHIP_PARTNER_EMAIL = "Relationship Partner Email";
export const NUMBER_OF_FEMALE_ASSOCIATES = "Number of Female Associates";
export const NUMBER_OF_MINORITY_ASSOCIATES = "Number of Minority Associates";
export const EXPORT_WARNING="Configure your export before downloading."
export const EXPORT_USERS="Export Users"
export const MATTER_DOCUMENTS = "Matter Documents"
export const ACTIONS = 'Actions';
export const ITEM_NAME = 'Item Name';
export const LINK_COPIED = 'link copied to clipboard.';
export const NO_DOCUMENTS = "No New Documents";
export const ADD_NEW_DOCS = "Add New Documents";
export const NEW_DOCUMENTS = "New Documents";
export const ADD_NEW_DOCS_TAG_LINE = "Upload, tag, and create folders for documents.";
export const DROPZONE_LABEL = "Drop e-mails or files to upload";
export const DOCUMENT_UPLOAD_TAGS_TITLE="Add tags to documents";
export const ACCEPTED_FILES_LABEL = `jpeg, jpg, png, docx, doc, xlsx, xls, txt, html, htm, pptx, ppt, msg, eml, tiff,
  plain, csv, mpeg, wav, webm, quicktime, mp4, pdf, json, msword, rtf, rfc822`;
export const ACCEPTED_INVOICES_FILES_LABEL = `Click to upload or drag and drop LEDES 1998B  ( txt , dat )  formatted invoices.`;
export const UPLOAD_INVOICES = "Upload Invoices";
export const SUBMISSION_STATUS = "Submission Status";
export const FINANCIALS = "Financials";
export const PRACTICE_AREA_RATES = "Practice Area Rates"
export const RATE = "Rate";
export const CLASSIFICATION = "Classification";
export const CREATE_NEW_TIMEKEEPER = "Create New TimeKeeper";
export const REQUEST_NEW_TIMEKEEPER = "Request New TimeKeeper";
export const EDIT_TIMEKEEPER_TITLE =  "Edit Timekeeper Profile";
export const EDIT_ADMIN_TITLE =  "Edit Admin Profile";
export const TIMEKEEPERPRACTICEAREA = "Add Practice Area Rate for New Timekeeper";
export const CREATE_ADMIN = "Request New Admin";
export const ADMINPRACTICEAREA = "Create Practice Area Rate for New Admin";
export const TIMEKEEPER_ADMIN_SUMMARY = "Review New User Summary";
export const PRIMARYOFFICELOCATION = "Primary Office Location";
export const ORGANIZATION = "Organization";
export const ROLE = "Role";
export const STATE_BAR_LICENCE = "State Bar License";
export const SUBJECT_MATTER_EXPERTISE = "Subject Matter Expertise";
export const JUSTIFICATION_FOR_TIMEKEEPER = "Justification For Timekeeper User";
export const JUSTIFICATION_FOR_ADMIN = "Justification For Admin User";
export const JUSTIFICATION_FOR_ADMIN_TIMEKEEPER = "Justification For Admin and Timekeeper User";
export const JD_YEAR = "JD Year";
export const WALMART_APPOVING_ATTORNEY = "Walmart Approving Attorney";
export const APPROVAL_ATTORNEY = "Approval Attorney";
export const REQUESTER_EMAIL = "Requester Email";
export const TKID = "TKID";
export const BILLING_CONTACT = "Billing Contact";
export const CREATED_DATE = "Created Date";
export const PRACTICE_AREA = "Practice Area";
export const RATE_START_DATE = "Rate Start Date";
export const RATE_END_DATE = "Rate End Date";
export const JUSTIFICATION_FOR_RATE = "Justification for Rate";
export const DISCOUNT = "Discount";
export const NAME = "Name";
export const PHONE = "Phone #";
export const CONTACT_INFO = "Contact Info";
export const APPROVAL_DATA = "Approval Data";
export const PROFILE_INFO = "Profile Info";
export const RATE_S = "Rate(s)";
export const SUMMARY = "Summary";
export const ADD_ANOTHER_RATE = "Add Another Rate";
export const TIMEKEEPER_DETAILS = "Admin & Timekeeper User";
export const PRACTICEAREA_RATE_CARDS = "Practice Area Rate Cards";
export const NOT_SUBMITTED = "Not Submitted";
export const SUBMITTED = "Submitted";
export const TYPE = "Type";
export const SUBTYPE = "Subtype";
export const BALANCE = "Balance";
export const PAID = "Paid";
export const START = "Start";
export const END = "End";
export const VENDOR_ID = "Vendor ID";
export const ADDRESS_TYPE = "Address Type";
export const ADDRESS_LINE_1 = "Address Line 1";
export const ADDRESS_LINE_2 = "Address Line 2";
export const ZIPCODE = "Zipcode";
export const CITY = "City";
export const STATE = "State";
export const COUNTRY = "Country";
export const ROLE_INVOICE_COORDINATOR = "invoice_coordinator"
export const ROLE_TIMEKEEPER = "Timekeeper"
export const ROLE_ADMIN = "Admin"
export const OC_ADMIN = "OC_ADMIN";
export const OC_TIMEKEEPER = "OC_TIMEKEEPER";
export const OC_INVOICE_COORDINATOR = "OC_INVOICE_COORDINATOR";
export const DEACTIVATE_USER = "Deactivate User";
export const CONFIRM_TIMEKEEPER_REMOVAL = "Confirm Timekeeper Removal"
export const CONFIRM_TIMEKEEPER_REMOVAL_TEXT = "Removing user from a matter will revoke all the access to the matter"
export const SELECTED_MATTERS = "Selected Matters:"
export const MATTER_SPECIFIC_RATES = "Matter Specific Rates"
export const CURRENT_WALMART_RATE = "Current Walmart Rate"
export const CURRENT_STANDARD_RATE = "Current Standard Rate"
export const PROPOSED_WALMART_RATE = "Proposed Walmart Rate"
export const APPROVED_WALMART_RATE = "Approved Walmart Rate"
export const APPROVING_ATTORNEY = "Approving Attorney"
export const APPROVED_RATE = "Approved Rate"
export const ADD_NEW_RATE = 'Add New Rate'
export const PEOPLE = "People"
export const START_END_DATE_VALIDATION_MESSAGE = "End date cannot be prior to start date!"
export const RECEIVED_DATE = "Received Date"
export const LABEL_PENDING="pending";
export const LABEL_APPEALED="appealed";
export const LABEL_FAILED="Failed";
export const LABEL_PAID="Paid";
export const LABEL_REJECTED="Rejected";
export const INVOICE_APPROVE_API = `/workflow-executions/v1/approve`;
export const INVOICE_REJECT_API = `/workflow-executions/v1/reject`;
export const INVOICE_HOLD_APPEALS_API = `/invoices/v1/status`;
export const INVOICE_REJECT_REASON = `Rejection Reason`;
export const INVOICE_REJECT_COMMENT = `Rejection Comment (optional)`;
export const INVOICE_DROPZONE_LABEL = "or drag files here";
export const ADJUSTMENT_TYPE = "Adjustment Type";
export const VALUE = "Value";
export const NET_AMOUNT = "Net Amount";
export const TOTAL_NET_AMOUNT = "Total Net Amount";
export const UNITS = "Units";
export const DISCOUNTS = "Discounts";
export const ADJUSTMENTS = "Adjustments";
export const ADJUSTMENT_REASON = "Adjustment Reason";
export const COMMENT = "Comment";
export const DETAILS = "Details";
export const ADJUST_UNITS = "Adjust Units";
export const CONFIRM_DOCUMENT_DELETION = "Confirm Document Deletion";
export const MANAGE_TAGS = 'Manage Tags';
export const FIND_OR_CREATE_TAG = 'Find or create a tag';
export const SHOW_NO_RESULT = "SHOW_NO_RESULT";
export const LOG_IN = "Log In";
export const FORGOT_PASSWORD = "Forgot Password?";
export const LOGIN = "Login";
export const VERIFY_ITS_YOU = "Verify its you";
export const RESET_PASSWORD_TEXT = "Reset Password";
export const PASSWORD_RESET_TEXT = "We'll send a reset password code to the email provided above."
export const REMEMBER_YOUR_PASSWORD = "Remember your password?"
export const SHOW = "Show"
export const HIDE = "Hide"
export const SUCCESS = "SUCCESS"
export const HELP = "Help"
export const LANGUAGE = "Language"
export const INVALID_INPUT_PROVIDED = "INVALID_INPUT_PROVIDED"
export const LEDES_SAMPLE_FILE_NAME = "LEDES file format.xlsx"
export const LEDES_COMPATIBLE_FILE_MESSAGE = "Please use the compatible invoice file format."
export const LEDES_SAMPLE_FILE_LINK_MSG = "Download sample format."

export const SELECT_MATTER = "Select a matter";
export const MSR_WALMART_ATTORNEY_NAME_SELECT = "Select Walmart Attorney Name";
export const MSR_PRACTICE_AREA = "Select Practice Area";
export const SELECT_TAGS = "Select Tags";
export const SELECT_ADDRESS = "Select Address"
export const SELECT_EXPERTISE = "Select Expertise"
export const SELECT_SUBJECT_MATTER_EXPERTISE = "Select Subject Matter Expertise";
export const SELECT_STATE = "Select State";
export const SELECT_COUNTRY = "Select Country";
export const SELECT_STATE_BAR_LICENSE = "Select State Bar License";
export const SELECT_CLASSIFICATION = "Select Classification";
export const SELECT_TYPE = "Select type";
export const SELECT_FROM_MATTERS = "Select from Matters";
export const SELECT_TIMEKEEPERS = "Select Timekeepers"
export const INVOICE_REJECT_REASON_OPTION = `Select a reason for invoice rejection`;
export const SELECT_ADJUSTMENT_REASON = `Select Adjustment reason`;
export const REJECTION_REASON = 'Rejection Reason';
export const REJECTION_COMMENT = 'Rejection Comment';

export const ADD_ROW = "Add Row";
export const EDITABLE = "Editable";
export const NO_RESULTS_FOUND = "No Results Found";

export const COMPLETED = "Completed";
export const TITLE = "Title";
export const STATUS = "Status";
export const JOB_STATUS = "Process Status";
export const SUBMITTED_TIME = "Submitted Time";
export const COMPLETED_TIME = "Completed Time";
export const BULK_OPERATION_TYPE = "Bulk Operation Type";
export const LNG_BJO_INVOICE_VALIDATION_ERROR = "LNG_BJO_INVOICE_VALIDATION_ERROR"
export const JOB_COMMANDS = {
  "BULK_INVOICE_CREATE" : {
    "identifier": "taskIdentifier",
    "type":"Invoice"
  },
  "BULK_LEAD_PARALEGAL_ASSIGNMENT" :{
    "identifier": "matterNumber",
    "type":"Matter"
  },
  "BULK_LEAD_ATTORNEY_ASSIGNMENT" :{
    "identifier": "matterNumber",
    "type":"Matter"
  },
}
export const ASYNC = "ASYNC";
export const SYNC = "SYNC"
export const BULK_INVOICE_CREATE = "BULK_INVOICE_CREATE";
export const RESUBMIT_INVOICE = "Resubmit Invoice";
export const RESUBMIT_INVOICE_MSG = "Are you sure you want to Resubmit this invoice?";

// master data parameter names
export const MASTER_PARAMS = `matterStatus,matterType,invoiceTypeList,invoiceAdjustmentReason,invoiceRejectReasonCodes,
lineItemType,workflowResponsibility,invoiceStatus,eventType,documentTags,state,country,expertise,organizationAddressType,
classification,ethnicity,subjectMatterExpertise,stateBarLicense,cmiEvidenceItem,expenseCode,taskCode,phaseCode,activityCode,feeCode,timekeeperRateRejectReason,noteTags`

/** List and Map datas */
export const matterStatusValues = ["Open", "Close"];
export const lineSeparatorAfter = [AMOUNT_PAID, SUB_CREATED_BY, "Rejecting Party"];
export const ROLE_VALUES = ["Timekeeper", "Admin"];
export const adminExternalUsersStatusValues = ["Active", "Inactive", "Pending", "Rejected"];
export const jobStatusValues = ["In Progress", "Complete","Partial Success","Error","Failed"];
export const AddressTypes = ["Billing Address", "Physical Address", "Remit to address", "Shipping Address"]
export const MATTER_DOCUMENTS_TAB = [MATTER_DOCUMENTS, RECENTLY_MODIFIED, MY_STARRED];
export const acceptedExcelFilesForDocuments = [
  `.xlsx`
];
export const TAB_ITEMS = ["Line Items", "Documents", "Payment details"];
export const LINE_ITEM_TABS = ['Fees', 'Expenses'];
export const LINE_ITEM_TYPE_FEE = "fee";
export const LINE_ITEM_TYPE_EXPENSE = "expense";
export const LINE_ITEM_TYPE_FEE_DISCOUNT = "fee discount (if)";
export const LINE_ITEM_TYPE_EXPENSE_DISCOUNT = "expense discount (ie)";
export const ADJUSTED_LINE_ITEM_DESCRIPTION = `Invoice Level Adjustment/Discount Applied`
export const changeInvoiceStatusItems = [
  {
    "actionId": 0,
    "label": "Approve Invoice",
    "key": "approved",
    "isAboveDivider": true
  },
  {
    "actionId": 1,
    "label": "Reject Invoice",
    "key": "Rejected",
    "isAboveDivider": true
  },
  {
    "actionId": 2,
    "label": "Apply Hold",
    "key": "Hold",
    "isAboveDivider": false
  },
  {
    "actionId": 3,
    "label": "Send to Appeals",
    "key": "Appealed",
    "isAboveDivider": false
  },
];
export const acceptedFilesForDocuments = [
  `image/jpeg,
  image/png,
  application/pdf,
  application/zip,
  application/x-zip-compressed,
  text/plain,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  message/rfc822,
  .csv,
  text/csv,
  .docx,
  .html,
  .htm,
  .pptx`,
  "image/*",
  "text/*",
  "video/*",
  ".pptx",
  "audio/*",
  ".msg",
  "application/vnd.ms-outlook",
  ".eml",
  "message/rfc822",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "text/plain",
  "text/html",
  "text/csv",
  "audio/mpeg",
  "audio/wav",
  "audio/webm",
  "video/quicktime",
  "video/mp4",
  "video/mpeg",
  "application/pdf",
  "application/json",
  "application/vnd.ms-outlook",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-excel",
  SUB_XML_FORMAT_PRESENTATION,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/rtf",
  "message/rfc822",
  SUB_XML_FORMAT_PRESENTATION,
  "application/zip",
  "application/x-zip-compressed",
  ".rfc822",
];
export const acceptedFilesForInvoices = [
  `text/plain`,
  "text/plain",
  ".DAT",
];
export const MAX_FILE_UPLOAD_SIZE = 10485760 ; // in bytes ~= 10MB
export const MAX_FILE_UPLOAD_SIZE_LINE_ITEMS = 10485760;
export const MAX_FILE_UPLOAD_SIZE_INVOICES = 10485760 ; // in bytes ~= 10MB
export const MAX_FILE_UPLOAD_SIZE_MSG_LINE_ITEMS = "Maximum file upload size is 10 MB."
export const SUPPORTED_PREVIEW_FORMAT = [
  "html",
  "txt",
  "png",
  "jpg",
  "jpeg",
  "pdf"
];
export const MAX_FILE_PREVIEW_SIZE= 10485760 ; // in bytes ~= 10MB
export const lineItemAdjustmentTypes = [
  {
    key: 'Increase by Percentage',
    value: 'Increase by Percentage'
  },
  {
    key: 'Decrease by Percentage',
    value: 'Decrease by Percentage'
  },
  {
    key: 'Increase by Amount',
    value: 'Increase by Amount'
  },
  {
    key: 'Decrease by Amount',
    value: 'Decrease by Amount'
  },
  {
    key: 'Set to Amount',
    value: 'Set to Amount'
  }
]
//matter section conversion object
export const matterSections = {
  BASIC: 'basic',
  SOP: 'sop',
  IDENTIFICATION: 'identification',
  COURT: 'court',
  CLOSURE: 'closure',
  LOCATION: 'location',
  MISC: 'misc',
  PRODUCT: 'product',
  NEW: 'new',
  PHARMACY: 'pharmacy',
  TRUCKING: 'trucking'
}
//sop Status conversion object
export const sopStatusConversion = {
  ACCEPTED_RELATED: 'Accepted-Related',
  ACCEPTED_NEW: 'Accepted-New',
  ARCHIVED: 'Archived',
  FORWARDED: 'Forwarded',
  IN_REVIEW: 'In-Review',
  NEW: 'New',
  NEW_CORRECTED: 'New-Corrected',
}
export const budgetLabels = {
  status: "Status",
  lastEdited: "Last Edited",
  balanceAmount: "Total Balance",
  paidAmount: AMOUNT_PAID,
  matterName: MATTER_NAME,
  matterNumber: MATTER_NUMBER,
  organizationName: ORGANIZATION,
  type: "Sub-Type",
  submitted: SUBMITTED,
  createdBy: SUB_CREATED_BY,
}
export const invoiceStatus = {
  "Workflow": {
    label: "Workflow",
    color: "gray",
    showHeaderActionPanel: true,
    disableChangeStatusButton: false
  },
  "Pending": {
    label: "Pending",
    color: "gray",
    showHeaderActionPanel: true,
    disableChangeStatusButton: true
  },
  "Pre-Workflow": {
    label: "Pre-Workflow",
    color: "gray",
    showHeaderActionPanel: true,
    disableChangeStatusButton: true
  },
  "Void": {
    label: "Void",
    color: "gray",
    showHeaderActionPanel: false,
    disableChangeStatusButton: true
  },
  "Appealed": {
    label: "Appealed",
    color: "red",
    showHeaderActionPanel: true,
    disableChangeStatusButton: true
  },
  "Hold": {
    label: "Hold",
    color: "spark",
    showHeaderActionPanel: true,
    disableChangeStatusButton: false
  },
  "Ready For Payment": {
    label: "Ready For Payment",
    color: "green",
    showHeaderActionPanel: false,
    disableChangeStatusButton: true
  },
  "Sent For Payment": {
    label: "Sent For Payment",
    color: "green",
    showHeaderActionPanel: false,
    disableChangeStatusButton: true
  },
  "Paid": {
    label: "Paid",
    color: "green",
    showHeaderActionPanel: false,
    disableChangeStatusButton: true
  },
  "Rejected": {
    label: "Rejected",
    color: "red",
    showHeaderActionPanel: false,
    disableChangeStatusButton: true
  },
  "Failed": {
    label: "Failed",
    color: "red",
    showHeaderActionPanel: true,
    disableChangeStatusButton: true
  },
};
export const fileMimeType = {
  'png': 'image/png',
  'jpg': 'image/jpg',
  'jpeg': 'image/jpeg',
  'pdf': 'application/pdf',
  'txt': 'text/plain',
  'doc' : 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'htm': 'text/htm',
  'html': 'text/html',
  'ppt' : 'application/vnd.ms-powerpoint',
  'pptx' : SUB_XML_FORMAT_PRESENTATION,
  'tiff': 'image/tiff',
  'xls' : 'application/vnd.ms-excel',
  'xlsx' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'csv' : 'text/csv'
};
export const budgetStatusTagColors = {
  'pending': {
    'label': 'Pending',
    'color': 'gray',
    'variant': 'tertiary'
  },
  'approved': {
    'label': 'Approved',
    'color': 'green',
    'variant': 'tertiary'
  }
};
export const userStatusTagColors = {
  'Inactive': {
    'color': 'purple'
  },
  'Active': {
    'color': 'green',
  },
  'Pending': {
    'color': 'spark',
  },
  'Rejected': {
    'color': 'red',
  },
}
export const dateLabels = {
  today: "Today",
  tomorrow: "Tomorrow",
  yesterday: "Yesterday"
};
export const detailModes = {
  CREATE: "create",
  VIEW: "view",
  EDIT: "edit",
};

export const INVOICE_LINE_ITEM_HEADERS = [
  "Type",
  "Date",
  "Description",
  "UTBMS",
  "Act",
  "TK",
  "Units",
  "Rate",
  "Rate - Currency",
  "Disc - Amount (Non Mandatory)",
  "Disc - Currency (Non Mandatory)",
  "Adj - Amount (Non Mandatory)",
  "Adj - Currency (Non Mandatory)",
  "State (Non Mandatory)",
  "Net",
  "Net Amt - Currency (Non Mandatory)"
];

export const INVOICE_LINE_ITEM_HEADERS_MANDATORY = [
  "Type",
  "Date",
  "Description",
  "UTBMS",
  "Act",
  "TK",
  "Units",
  "Rate",
  "Net"
];

// Start of Composite Search constants
export const CS_SCOPES = {
  MATTER: "matter",
  ORGANIZATION: "organization",
  PEOPLE: "people",
  INVOICE: "invoice",
  BUDGET: "budget"
};
export const MATTER_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "createdTs",
    "type": "desc"
  }]
}
export const CS_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "createdTs",
    "type": "desc"
  }]
}
export const PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "firstName",
    "type": "asc"
  }]
}

export const MATTER_AUTOCOMPLETE_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "matterName",
    "type": "asc"
  }]
}

export const MATTER_NUMBER_AUTOCOMPLETE_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "matterNumber",
    "type": "asc"
  }]
}

export const ORG_AUTOCOMPLETE_DEFAULT_SORT_QUERY = {
  "sortBy": [{
    "key": "companyName",
    "type": "asc"
  }]
}
// this will be removed once type ahead is in place for all autocomplete
export const CS_DEFAULT_PAGINATION_QUERY = {
  "offset": 0,
  "limit": 1000,
}
export const CS_IS_ACTIVE_FILTER = {
  "key": "isActive",
  "value": true,
  "operation": "match"
}

export const CS_IS_STATUS_FILTER = {
  "key": "status",
  "value": "Active",
  "operation": "match"
}

export const CS_IS_EXTERNAL_USER_FILTER = {
  "key": "isExternalUser",
  "value": true,
  "operation": "match"
}

export const CS_IS_TIMEKEEPER_ROLE_ENABLED_FILTER = {
  "key": "isTimeKeeperRoleEnabled",
  "value": true,
  "operation": "match"
}

export const CS_IS_LEAD_ATTORNEY_FILTER = [
  {
    "key": "roleCodes",
    "value": "ATTORNEY",
    "operation": "match"
  },
  {
    "key": "status",
    "value": "Active",
    "operation": "match"
  }
]

export const CS_TYPEAHEAD_LIMIT = 10;
export const CS_TYPEAHEAD_PAGINATION_QUERY = {
  "offset": 0,
  "limit": CS_TYPEAHEAD_LIMIT,
}
export const CS_TYPEAHEAD_PAGINATION_QUERY_FOR_MATTER = {
  "offset": 0,
  "limit": 20,
}
// End of Composite Search constants
/** End of List and Map datas */

// Start of Organization Autocomplete constants
export const NA_ORG_ID = "00000000-0000-0000-0000-000000000000";
export const NA_ORG_COMPANY_NAME = "N/A";
// End of Organization Autocomplete constants

// NA stateBarLicense dropdown
export const STATE_BAR_LICENSE_NA = {
  "id": "N/A",
  "type": "stateBarLicense",
  "code": "",
  "value": "N/A",
  "description": "N/A",
  "isActive": true,
}


export const CS_SCORE_SORT_OBJ = {
  "key": "_score",
  "type": "desc"
}

export const CS_RESERVED_CHARACTERS = ['\\','+','=','&&','||','!','(',')','{','}','[',']','^','"','~','*','?',':','/']; // Kept in a specific order

export const EXPORT_MAX_LIMIT_CONF = {
  MATTER: 1000,
  INVOICE: 1000,
  BUDGET: 1000,
  EXTERNAL_USER: 1000,
  NOTE: 1000,
  USER_REQUESTS: 1000
}

export const HELP_CENTER_TABS = ['Training guides'];
export const HELP_CENTER = "Help Center";
export const ACCOUNT = "Account";
export const OPEN_IN_NEW_TAB ="Open in new tab";
export const COPY_SHARABLE_LINK = "Copy sharable link";
export const TRAINING_GUIDES_ITEMS = [
  {
      label: "General",
      key: "General",
  },
{
      label: "Financials",
      key: "Financials",
},
  {
      label: "Matters",
      key: "Matters",
  },
  {
      label: "Admin",
      key: "Administrator",
  },
];
export const SUPPORTED_PREVIEW_FORMAT_HELP_CENTER = [
  "html",
  "txt",
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "video",
  "audio",
  "iframe",
];

export const NO_CACHE_HEADER  = {
  "Cache-Control" : "no-cache"
}
export const MEDIA_TYPE = {
  PDF: "pdf",
  AUDIO: "audio",
  VIDEO: "video",
  PNG: "png",
  JPEG: "jpeg",
  TXT: "txt"
}
export const CONTENT_SOURCE = {
  CANOPUS: "CANOPUS",
  MS_STREAM: "MS_STREAM",
  VIMEO: "VIMEO"
}
export const VIDEO = "video"
export const DISCLAIMER = "By logging into the eWMMS application, you acknowledge that you are an authorized user and agree to comply with all applicable laws, regulations, and rules of professional conduct. Unauthorized access or use of the system is strictly prohibited. Any information or data accessed or shared through this system should be treated as confidential and privileged information. Matter-specific information is intended to be accessed only by parties necessary for the purpose of providing legal advice. You are responsible for maintaining the confidentiality of your login credentials and for any activity that occurs under your account. Walmart reserves the right to monitor and record all activities on this system to ensure compliance with Walmart policies, applicable laws, and applicable rules of professional conduct. By using this system, you consent to such monitoring and recording. By logging into this application, you acknowledge that you have read, understood, and agreed to the terms and conditions of this disclaimer. If you do not agree to these terms, please log out immediately and contact Walmart Outside Counsel Management."
export const USER_REQUESTS = `User Requests`;
export const SELECT_ATLEAST_ONE_ROW = "Please select at least one row!";
export const EXPORT_CONFIG_TEXT = "Configure your export before downloading.";
export const LOCKED_STATE = "LOCKED";
export const LOGIN_FAILED_STATE = "FAILED";
export const USER_ACCOUNT_LOCKED_LINK = "https://wmt.quickbase.com/db/btja2363r/a3126c2c-f91f-44b2-8d67-26a4528ba8d5";
export const INVOICE = "Invoice";
export const BUDGETS = "Budgets";
export const MATTERS = "Matters";
export const NOTES = "Notes";
