import React, { useEffect, useState } from "react";
import {
  Divider,
  SideNavigation,
  SideNavigationItem,
} from "@walmart-web/livingdesign-components";
import {
  NavIconInvoicing,
  NavIconMatters,
  NavIconPeople,
  WalmartSpark,
} from "../../../constants/images";
import { createUseStyles } from "react-jss";
import { SIDE_NAVIGATION_WIDTH } from "../../../constants/constants";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Clock } from "@walmart-web/livingdesign-icons";
import { FEATURES } from "../../../constants/features";

const useStyles = createUseStyles({
  navigation: {
    width: SIDE_NAVIGATION_WIDTH,
    background: 'white',
    height: "100%",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.15)",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    width: 224,
    margin: 16,
  },
  logo: {
    padding: 8,
  },
  sideNavigation: {
    paddingTop: 40,
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
    marginBottom: 70,
    "& ul": {
      height: "100%",
      wordBreak: "break-all",
    },
  },
  search: {
    backgroundColor: "#f8f8f8",
    borderRadius: 32,
  },
});

const Navigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isCurrent, setIsCurrent] = useState("matter");
  const sidemenuAccess = useSelector((state) => state?.accessibility?.sidemenuAccess);
  const featureMap = useSelector((state) => state?.user?.featureMap);

  const { pathname } = useLocation();

  // set active tab
  useEffect(()=>{
    const path = pathname.split("/")
    const validPath = items?.findIndex((value) => value?.key === path[1]);
    if (pathname === "/") {
      setIsCurrent("matter")
    }
    else if (validPath >= 0) {// works only if the route is present within the items array(to skip the nested routes).
      setIsCurrent(path[1])
    }
  },[pathname])

  const items = [
    {
			label: "Matters",
      icon: <NavIconMatters />,
      path: "/",
      isBelowBreaker: false,
      key: "matter",
      showTab: sidemenuAccess?.matters
    },
		{
			label: "Financials",
			icon: <NavIconInvoicing />,
			path: "/financial",
			isBelowBreaker: false,
			key: "financial",
      showTab: sidemenuAccess?.financial
		},
    {
      label: "Organization",
      icon: <NavIconPeople />,
      path: "/organization",
      isBelowBreaker: false,
      key: "organization",
      showTab: sidemenuAccess?.organization
    },
    {
      label: "Process Status",
      icon: <Clock size="medium" />,
      path: "/processStatus",
      isJobStatus: true,
      key: "processStatus",
      showTab: featureMap?.[FEATURES.BULK_JOB] || false
    },
  ];

  const createItem = (value, index) => (
    <SideNavigationItem
      key={value?.key}
      onClick={() => handleClick(value, value?.key)}
      leading={value?.icon}
      isCurrent={isCurrent === value?.key}
      data-testid={`nav-item-${value?.label?.split(' ').join('-')}`}
      id={`nav-item-${value?.label?.split(' ').join('-')}`}
    >
      {value?.label}
    </SideNavigationItem>
  );

  const aboveBreaker = [];
  const belowBreaker = [];
  const processStatus = []
  items?.forEach((value, index) => {
    const item = createItem(value, index);
    if (value?.showTab) {
      if (value.isJobStatus === undefined) {
        value.isBelowBreaker === false
          ? aboveBreaker.push(item)
          : belowBreaker.push(item);
      } else {
        processStatus.push(item)
      }
    }
  });

  const displayItems = [
    ...aboveBreaker,
    <Divider key="divider_2" className={classes.divider} />,
    ...belowBreaker,
    ...(processStatus.length > 0 ? processStatus : [])
  ];

  const handleClick = (value, key) => {
    setIsCurrent(key);
    history.push({ pathname: value?.path, state: value?.state });
  };

  return (
    <div className={classes.navigation}>
      <WalmartSpark className={classes.logo} />
      <SideNavigation className={classes.sideNavigation}>
        {displayItems}
      </SideNavigation>
    </div>
  );
};

export default Navigation;