import types from "../types/masterDataActionTypes";

export const initialState = {
  taskWorkflowId: 'd4ad3108-d1c8-4764-ac73-5107beb84d28',
  matterDropDownData: [],
  matterFilterDropDownData:{
    nodeList:[]
  },
  peopleDropDownData:{
    nodeList:[]
  },
  externalPeopleDropDownData:{
    nodeList:[]
  },
  organizationDropDownData: {
    nodeList:[]
  },
  organizationData: [],
  userData: [],
  comments: [],
  matterDetailSections: {
    Tort: ['General', 'Basic Matter Fields', 'Related Matters', 'Party', 'Matter Identification Feild', 'Legal Holds']
  },
  practiceAreaValues: [
    "Compliance",
    "Compliance; Regulatory",
    "Corporate",
    "Corporate; Finance",
    "E-Commerce",
    "Employment Practices",
    "Employment Practices; Corporate; Litigation - Employment",
    "Employment Practices; Litigation - Employment",
    "Finance",
    "Global Sourcing; Supply Chain",
    "Governance",
    "GSATE",
    "GSATE; Litigation Support Group",
    "Health and Wellness",
    "Intellectual Property",
    "Intellectual Property; Compliance",
    "International",
    "International; E - Commerce",
    "International; International Finance; E - Commerce",
    "Legal Operations; Conversion",
    "Litigation - Commercial; Litigation - Class Action; Litigation - IP",
    "Litigation - Employment; Employment Practices",
    "Litigation Support Group",
    "Litigation Support Group; E - Commerce; Regulatory",
    "Litigation Support Group; GSATE",
    "Merchandising - Walmart US",
    "Mergers & Acquisitions",
    "Mergers & Acquisitions; Walmart Services",
    "Puerto Rico",
    "Puerto Rico; International; Litigation - Tort",
    "Puerto Rico; Litigation - Class Action; Litigation - Commercial; Litigation - Tort; Litigation - IP",
    "Puerto Rico; Litigation - Tort",
    "Puerto Rico; Litigation - Tort; Litigation - IP; Litigation - Class Action; Litigation - Commercial",
    "Real Estate; Health and Wellness",
    "Regulatory",
    "Sam's Club",
    "Sam's Club; E-Commerce",
    "Supply Chain; Global Sourcing",
    "Walmart Services",
    "Walmart Stores",
    "Walmart Stores; Sam's Club; Real Estate; E-Commerce",
  ],
  masterDatas: {}
};


const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MATTER_DATA:
      return {
        ...state,
        matterData: action.payload
      }
    case types.SET_MATTER_DROP_DOWN_DATA:
      return {
        ...state,
        matterDropDownData: action.payload
      }
    case types.SET_MATTER_FILTER_DROP_DOWN_DATA:
      return {
        ...state,
        matterFilterDropDownData: action.payload
      };
    case types.SET_PEOPLE_DROP_DOWN_DATA:
      return {
        ...state,
        peopleDropDownData: action.payload
      }
    case types.SET_MASTER_DATA:
      return {
        ...state,
        masterDatas: {
          ...state.masterDatas,
          ...action.payload,
        },
      };
    case types.SET_EXTERNAL_PEOPLE_DROP_DOWN_DATA:
      return {
        ...state,
        externalPeopleDropDownData: action.payload
      }
    case types.SET_ORGANIZATION_DROP_DOWN_DATA:
      return {
        ...state,
        organizationDropDownData: action.payload
      }
    default:
      return state;
  }
};

export default masterDataReducer;
