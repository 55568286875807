import MuiTextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import {
  Button, Divider, FormGroup, Grid, Skeleton,
  GridColumn, Heading, Radio, useSnackbar
} from "@walmart-web/livingdesign-components";
import { Plus } from "@walmart-web/livingdesign-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getService, postService } from "../../../../axios/axios";
import MatterAutocomplete from "../../../../components/Autocomplete/MatterAutocomplete/MatterAutocomplete";
import {
  BUDGET_AMOUNT, BUDGET_NAME,
  detailModes as modes, DUE_DATE, FIND_MATTER,
  ORGANIZATION,
  START_END_DATE_VALIDATION_MESSAGE,
  DESCRIPTION,
  START_DATE,
  END_DATE} from "../../../../constants/constants";
import { BUDGET_SAVE_MESSAGE, BUDGET_UPDATE_MESSAGE, INVALID_DATE_FORMAT_MESSAGE } from "../../../../constants/messages";
import Detail from "../../../Detail/Detail";
import Label from "../../../../components/TextField/Label/Label";
import { BUDGET_SERVICE } from "../../../../constants/baseURLs";
import { budgetDetailValidationRules } from "../../../../Validation/validationRules";
import MuiValidationMessage from "../../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { DUE_DATE_VALIDATION_MESSAGE } from "../../../../Validation/validationMessages";
import { getErrorMessage, onInputNumberKeyDown, renderTernary } from "../../../../helpers/utils";
import { BACKEND_DATE_FORMAT, DATE_END_TIME, DATE_START_TIME, DATE_YYYY_MM_DD, INVALID_DATE, MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { filterPayload } from "../../../../helpers/commonUtils";
import { REMOVE, SUBMIT, DISCARD } from "../../../../constants/actionButtons";
import UtbmsActivityAutocomplete from "../../../../components/Autocomplete/UtbmsActivityAutocomplete/UtbmsActivityAutocomplete";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { convertToUTCDateTime } from "../../../../helpers/utcUtils";
import { CreateEditBudgetStyles } from "./CreateEditBudget.styles";

const Title = {
  edit: "Edit Budget",
  create: "Add Budget",
};

let idNum = 0;

const CreateEditBudget = () => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [mode, setMode] = useState(null);
  const { matterDetail }=useSelector((state) => state?.matter);
  const { state: { budgetId = "0", fromMatter } = {} } = useLocation();
  const {
    identifier,
    companyName
	} = useSelector((state) => state?.organization?.organizationDetailValues);
  const initialData = {
    budgetType: "Total",
    timePeriod: "LIFE_OF_MATTER",
    status: "draft",
    startDate: "",
    endDate: "",
    dueDate: moment().format(BACKEND_DATE_FORMAT),
    matter: fromMatter ?
    {
      matterName: matterDetail?.matter?.matterName,
      referenceKey:matterDetail?.matter?.matterNumber,
      matterId: matterDetail?.matter?.identifier ,
      practiceAreaCode: matterDetail?.matter?.practiceAreaCode
    }: null,
    isInternal: true,
    matterId: fromMatter ? matterDetail?.matter?.identifier : null,
    organization: {},
    budgetName: "",
    budgetAmount: "",
  };
  const [title, ] = useState({ ...Title });
  const [data, setData] = useState({ ...initialData });
  const [detailData, setDetailData] = useState({});
  const classes = CreateEditBudgetStyles()
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const { register, formState: { errors }, setError, clearErrors } = useForm({ mode: "all" });
  const [phaseItemsError, setPhaseItemsError] = useState([]);
  const masterDetails = useSelector(state => state?.masterData?.masterDatas);
  useEffect(() => {
    if (budgetId === "0") {
      setMode(modes.CREATE);
    } else {
      setMode(modes.EDIT);
    }
  }, [data, budgetId]);

  useEffect(() => {
    if (mode === "edit") {
      getBudgetDetail();
    }
  }, [mode]);

  useEffect(() => {
    if (data?.budgetType?.toLowerCase() === "phase" && !data?.budgetPhases){
      const addFirstPhaseData = [{
        id : ++idNum,
        new : true,
        code: null,
        displayName: "",
        budgetAmount: 0,
      }];
      setData({...data, budgetPhases : addFirstPhaseData});
    }
  }, [data?.budgetType]);


  useEffect(() => {
    if(data?.timePeriod === 'CUSTOM_DATE_RANGE' && data?.endDate && data?.startDate){
    if (data?.endDate || data?.startDate) {
      if ((new Date(data?.startDate) > new Date(data?.endDate))) {
        setData({ ...data, startDate: data?.startDate, endDate: moment(data?.startDate).add(1, 'days').format(DATE_YYYY_MM_DD) });
      }
    }
  }
  }, [data?.startDate])

  useEffect(() => {
    if(data?.timePeriod === 'CUSTOM_DATE_RANGE' && data?.endDate && data?.startDate){
    if ((new Date(data?.endDate) < new Date(data?.startDate))) {
      setError('endDate', { type: 'custom', message: START_END_DATE_VALIDATION_MESSAGE });
    }
    else {
      //If there is Invalid date format error then it should not clear the errors
      if(errors?.endDate?.message !== INVALID_DATE_FORMAT_MESSAGE )
      { clearErrors('endDate') }
    }}
  }, [data?.endDate])

  useEffect(() => {
    const d1 = moment(new Date(data?.dueDate)).format(MOMENT_DATE_FORMAT)
    const d2 = moment(new Date()).format(MOMENT_DATE_FORMAT)
    if (Date.parse(d1) < Date.parse(d2)) {
      setError('dueDate', { type: 'custom', message: DUE_DATE_VALIDATION_MESSAGE });
    }
    else {
      //If there is Invalid date format error then it should not clear the errors
      if(errors?.dueDate?.message !== INVALID_DATE_FORMAT_MESSAGE )
      { clearErrors('dueDate') }
    }
  }, [data?.dueDate])

  /**
   * This function is called when api call throws an error
   */
   const handleError = (error) => {
    setLoading(false);
      addSnack({
        message: getErrorMessage(error),
      });
  }

  /**
     * Function to call Budget Service
     */
  const getBudgetDetail = () => {
    setDataLoading(true);
    getService(BUDGET_SERVICE, `/budgets/v1/${budgetId}`)
    .then((res) => {
      const detail = res?.data;
      const matter = { matterName: detail?.matterName, referenceKey: detail?.matterNumber, matterId: detail?.matterId, practiceAreaCode: detail?.practiceAreaCode }
      const organization = { organizationName: detail?.organizationName, referenceKey: detail?.organizationId }
        setData({ ...data, ...detail, matter, organization, budgetType: detail?.type })
        setDetailData({ ...data, ...detail, matter, organization, budgetType: detail?.type });
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        addSnack({
          message: getErrorMessage(error),
        });
      });
  };

  const setUtbmsCode = (v, id) => {
    handleInputChangeBudgetPhase("code", v, id)
  };

  const handleInputChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const onCreateHandler = () => {
    setLoading(true);
    let sparebudgetPhases;
    if(data?.hasOwnProperty("budgetPhases")){
      sparebudgetPhases = data?.budgetPhases;
      const budgetPhases = data?.budgetPhases?.map((item) => {
        const codes = JSON.parse(item?.code);
        return {...item, code: codes?.value};
      })
      data.budgetPhases = budgetPhases;
    }
    const body = {
      budgetName: data?.budgetName,
      matterId: fromMatter ? matterDetail?.matter?.identifier : data?.matter?.matterId,
      matterNumber: fromMatter ? matterDetail?.matter?.matterNumber : data?.matter?.referenceKey ,
      matterName: fromMatter ? matterDetail?.matter?.matterName : data?.matter?.matterName,
      practiceAreaCode: fromMatter ? matterDetail?.matter?.practiceAreaCode : data?.matter?.practiceAreaCode,
      organizationId: identifier,
      organizationName: companyName,
      dueDate: convertToUTCDateTime({date: data?.dueDate, time: DATE_END_TIME}),
      timePeriod: data?.timePeriod,
      ...(data?.timePeriod === "CUSTOM_DATE_RANGE" &&
      {startDate: convertToUTCDateTime({date: data?.startDate, time: DATE_START_TIME}),
      endDate: convertToUTCDateTime({date: data?.endDate, time: DATE_END_TIME})}),
      type: data?.budgetType,
      budgetAmount: data?.budgetAmount,
      budgetPhases: data?.budgetType?.toLowerCase() === "phase" ? data?.budgetPhases : [],
      isInternal: data?.isInternal,
    };
    const filteredBody=filterPayload(body)
    postService(BUDGET_SERVICE, `/budgets/v1`, filteredBody)
      .then((res) => {
        history.push(`/budget/${res?.data?.id}`, { budgetType: data?.budgetType, fromMatter: fromMatter });
        setLoading(false);
        addSnack({
          message: BUDGET_SAVE_MESSAGE,
        });
      })
      .catch((error) => {
        if(data?.hasOwnProperty("budgetPhases")){
          data.budgetPhases = sparebudgetPhases;
        }
        handleError(error)
      });
  };

  /**
   * Gets called when clicked on save button.
   */
   const onUpdateHandler = () => {
    setLoading(true);
    let sparebudgetPhases;
    if(data?.hasOwnProperty("budgetPhases")){
      sparebudgetPhases = data?.budgetPhases;
      const budgetPhases = data?.budgetPhases?.map((item) => {
        if(item?.hasOwnProperty("new") && item?.new){
          const codes = JSON.parse(item?.code);
          return {...item, code: codes?.value};
        }
        return item;
      })
      data.budgetPhases = budgetPhases;
    }
     const body = {
       budgetName: data?.budgetName,
       matterId: fromMatter ? matterDetail?.matter?.identifier : data?.matter?.matterId,
       matterNumber: fromMatter ? matterDetail?.matter?.matterNumber : data?.matter?.referenceKey ,
       matterName: fromMatter ? matterDetail?.matter?.matterName : data?.matter?.matterName,
       practiceAreaCode: fromMatter ? matterDetail?.matter?.practiceAreaCode : data?.practiceAreaCode,
       organizationId: data?.organization?.referenceKey,
       organizationName: data?.organization?.organizationName,
       dueDate: convertToUTCDateTime({date: data?.dueDate, time: DATE_END_TIME}),
       timePeriod: data?.timePeriod,
       ...(data?.timePeriod === "CUSTOM_DATE_RANGE" &&
       {startDate: convertToUTCDateTime({date: data?.startDate, time: DATE_START_TIME}),
       endDate: convertToUTCDateTime({date: data?.endDate, time: DATE_END_TIME})}),
       type: data?.budgetType,
       budgetAmount: data?.budgetAmount,
       budgetPhases: data?.budgetType?.toLowerCase() === "phase" ? data?.budgetPhases : [],
       isInternal: data?.isInternal,
     };
     const filteredBody=filterPayload(body)
     postService(BUDGET_SERVICE, `/budgets/v1`,filteredBody)
     .then((res) => {
      const newBudgetId = res?.data?.id;
        history.push(`/budget/${newBudgetId}`);
        setLoading(false);
        addSnack({
          message: BUDGET_UPDATE_MESSAGE,
        });
      })
      .catch((error) => {
        if(data?.hasOwnProperty("budgetPhases")){
          data.budgetPhases = sparebudgetPhases;
        }
        handleError(error)
      });
  };

  const handleAddPhase = () => {
    const addData = {
      id: ++idNum,
      new: true,
      code: null,
      displayName: "",
      budgetAmount: 0,
    };
    const date = [...data?.budgetPhases, addData];
    setData({ ...data, budgetPhases: date });
  };

  const handleRemoveClick = (id) => {
    const filteredData = data?.budgetPhases?.filter((row) => row?.id !== id);
    setData({ ...data, budgetPhases: filteredData });
  };

  const handleDateError = (err, dateType) => {
    if(err)
    {
      setError(dateType, { type: 'custom', message: INVALID_DATE_FORMAT_MESSAGE })
    }
    else{
      clearErrors(dateType)
    }
  };
  const handleInputChangeBudgetPhase = (name, value, id) => {
    /*This function is calling under loop so when this function called we are getting
    the item index and then checking if the name is budgetAmount and it's value is empty so we are putting
    the validation message on particular index's budget amount and else we are setting the data for submit*/
    if(name==='budgetAmount' && !value){
      if (phaseItemsError?.indexOf(i => i.rowIndex===id)===-1){
        setPhaseItemsError([...phaseItemsError, {
          name: 'budgetAmount',
          error: true,
          message: 'Required',
          rowIndex: id
        }]);
      }
    }else {
    if(name === 'budgetAmount'){
        /* IN this else when value is correct so here we are checking if the value is there and
        the error message still there so we are removing the error message here*/
        const UpdatedPhaseItemsError = [ ...phaseItemsError ]
        const itemToRemoveIndex = UpdatedPhaseItemsError?.findIndex(function(item) {
          return item?.rowIndex === id;
        });
        if(itemToRemoveIndex !== -1){
          UpdatedPhaseItemsError?.splice(itemToRemoveIndex, 1);
        }
        setPhaseItemsError(UpdatedPhaseItemsError);
      }
    }
      const itemIndex = data?.budgetPhases?.findIndex(function(item) {
        return item?.id === id;
      });
      const updatedBudgetPhases = JSON.parse(JSON.stringify(data?.budgetPhases));
      if(name === 'code'){
        updatedBudgetPhases[itemIndex]['displayName'] = JSON.parse(value)?.description;
      }
      updatedBudgetPhases[itemIndex][name] = value;
      let updatedData = JSON.parse(JSON.stringify(data));
      updatedData = {...updatedData, budgetPhases: updatedBudgetPhases}
      setData(updatedData);
  };

  const isBudgetPhasesValid = (list, savedList) => {
    if(list === undefined || list?.length < 1) {
      return false;
    }
    let flag = false;

    if(mode === "create") {
      list?.map((item, i) => {
        flag = item?.budgetAmount >= 0 && item?.code !== '' && item?.displayName !== ''
        return flag===true;
      });
    } else {
      if(savedList?.length > 0) {
        if(savedList?.length !== list?.length) {
          return true;
        }
        list?.map((item, i) => {
          if(!item?.new) {
            flag = item?.id !== savedList[i]?.id &&
              item?.budgetAmount !== '' &&
              item?.budgetAmount !== savedList[i]?.budgetAmount &&
              item?.code !== '' &&
              item?.code !== savedList[i]?.code &&
              item?.displayName !== '' &&
              item?.displayName !== savedList[i]?.displayName
            return flag===true;
          } else {
            flag = item?.budgetAmount !== '' &&
              item?.code !== '' &&
              item?.displayName !== ''
            return flag===true;
          }
        });
      } else {
        list?.map((item, i) => {
          flag = item?.budgetAmount >= 0 && item?.code !== '' && item?.displayName !== ''
          return flag===true;
        });
      }
    }
    return flag
  }

  let isDirty = false;
  if (budgetId === "0") {
    isDirty = data?.budgetName &&
    data?.matter?.matterId &&
    (data?.timePeriod === 'LIFE_OF_MATTER' ||
    data?.timePeriod === 'CUSTOM_DATE_RANGE' &&
    data?.startDate !== '' && data?.endDate !== '' && data?.startDate !== INVALID_DATE && data?.endDate !== INVALID_DATE) &&
    (data?.budgetType?.toLowerCase() === 'total' && data?.budgetAmount || data?.budgetType?.toLowerCase() === 'phase' && isBudgetPhasesValid(data?.budgetPhases));
  } else {
    isDirty =  detailData?.budgetName !== data?.budgetName ||
    detailData?.budgetAmount !== data?.budgetAmount ||
    detailData?.dueDate !== data?.dueDate ||
    detailData?.timePeriod !== data?.timePeriod ||
    detailData?.startDate !== data?.startDate ||
    detailData?.endDate !== data?.endDate ||
    (detailData?.budgetType?.toLowerCase() === 'total' && detailData?.budgetAmount !== data?.budgetAmount
    || detailData?.budgetType?.toLowerCase() === 'phase' && isBudgetPhasesValid(data?.budgetPhases, detailData?.budgetPhases) )
  }

  const isValid = data?.budgetName !== "" && data?.organization !== "" && data?.matter?.matterId !== "" &&
  data?.dueDate !== '' && data?.dueDate !== INVALID_DATE &&
  (data?.budgetType?.toLowerCase() === 'total' && data?.budgetAmount || data?.budgetType?.toLowerCase() === 'phase' && isBudgetPhasesValid(data?.budgetPhases)) &&
  (data?.timePeriod === 'LIFE_OF_MATTER' || (data?.timePeriod === 'CUSTOM_DATE_RANGE' &&
  data?.startDate !== '' && data?.endDate !== '' && data?.startDate !== INVALID_DATE && data?.endDate !== INVALID_DATE));


  return (
    <div className={classes.container}>
      <Detail
        mode={mode}
        setMode={setMode}
        breadcrumbValues={[]}
        subject={title.edit}
        newTitle={title.create}
        onCreate={onCreateHandler}
        onUpdate={onUpdateHandler}
        onCancel={() => history.goBack()}
        loading={loading}
        isValid={renderTernary(Object.keys(errors).length > 0, false, isValid)}
        isDirty={renderTernary(Object.keys(errors).length > 0, false, isDirty)}
        className={classes.container}
        hideDeleteBtn={true}
        editBtnTxt={SUBMIT}
        cancelText={DISCARD}
        // footerActions={<div>Version {mode === "edit" ? data?.version+1 : '1.00'}</div>}
      >
        <Grid>
          <GridColumn sm={9}>
            <div className={classes.leftContent}>
              <div
                className={[classes.columnSpacing, classes.divBorder].join(" ")}
                data-testid="budgetDetails"
              >
                <Heading as="h3" size="large" className={classes.subTitle}>
                  Budget Details
                </Heading>
                <Grid>
                  <GridColumn sm={12}>
                    <Label text={BUDGET_NAME} mandatoryField/>
                    {loading || dataLoading ?
                    <Skeleton height="58px" variant="rectangle" />
                    :
                      <MuiTextField
                        value={data?.budgetName}
                        data-testid="budget-name"
                        helperText={errors?.budgetName && <MuiValidationMessage  message={errors?.budgetName?.message}/>}
                        inputProps={{
                          id: "budgetName",
                          name: "budgetName",
                        }}
                        type="text"
                        fullWidth={true}
                        {...register(
                          'budgetName',
                          {...budgetDetailValidationRules?.budgetName, onChange: (e) => {handleInputChange(e.target.name, e.target.value)}}
                        )}
                        error={errors?.budgetName}
                      />
                    }
                  </GridColumn>
                  <div className={classes.contentSpacing16}>
                    <Label text={ORGANIZATION} mandatoryField/>
                    {loading || dataLoading ?
                    <Skeleton height="58px" variant="rectangle" />
                    :
                    <div className={classes.contentSpacing16}>
                        {companyName}
                    </div>
                  }
                  </div>
                  <div className={classes.contentSpacing16}>
                    <Divider color="#D3D3D3" />
                  </div>
                  <div className={classes.contentSpacing24}>
                    <Label text={DUE_DATE} />
                    {loading || dataLoading ?
                      <Skeleton height="58px" variant="rectangle" />
                      :
                      <div className={classes.contetSpacing8} >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            sx={{width:"100%"}}
                            required={true}
                            id="due-date-wrap"
                            value={renderTernary(data?.dueDate, moment(data?.dueDate), moment())}
                            onError={(err) => {
                              handleDateError(err,'dueDate')
                            }}
                            onChange={(e) => {
                              handleInputChange(
                                "dueDate",
                                moment(e).format(BACKEND_DATE_FORMAT)
                              );
                            }}
                            slotProps={{inputAdornment:{position:"start"},popper:{placement:"bottom-start",popperOptions:"bottom-start"},
                            textField:{helperText:errors?.startDate?.message,"data-testid":"due-date",
                            placeholder:"Enter a due date",name:"date",id:"due-date"}}}
                          />
                        </LocalizationProvider>
                        <div className={classes.dueDateError}>{errors?.dueDate && <MuiValidationMessage message={errors?.dueDate?.message}/>}</div>
                      </div>
                    }
                  </div>
                </Grid>
              </div>
              <div
                className={[classes.columnSpacing, classes.divBorder].join(" ")}
                data-testid="timePeriod"
              >
                <Heading as="h3" size="large" className={classes.subTitle}>
                  Time Period*
                </Heading>
                {loading || dataLoading ?
                    <Skeleton height="58px" variant="rectangle" />
                    :
                <FormGroup>
                  <Radio
                    label="Life of Matter"
                    name="timePeriod"
                    value="LIFE_OF_MATTER"
                    id="life-of-matter"
                    checked={data?.timePeriod === "LIFE_OF_MATTER"}
                    data-testid="time-period-life_of_matter"
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />

                  <Radio
                    label="Custom Date Range"
                    name="timePeriod"
                    id="custom-date-range"
                    value="CUSTOM_DATE_RANGE"
                    checked={data?.timePeriod === "CUSTOM_DATE_RANGE"}
                    data-testid="time-period-custom_date_range"
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                </FormGroup>
                }
                {loading || dataLoading ?
                  <Skeleton height="58px" variant="rectangle" />
                  :
                (data?.timePeriod === "CUSTOM_DATE_RANGE" && (
                  <div
                    className={classes.timePeriodSpacingTop}
                    data-testid="custom-range"
                  >
                    <Grid>
                      <GridColumn sm={5}>
                        {loading || dataLoading ?
                          <Skeleton height="58px" variant="rectangle" />
                          :
                        <div className={classes.contetSpacing8} >
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              sx={{width:"100%"}}
                              id="start-date-wrap"
                              required={true}
                              value={data?.startDate ? moment(data?.startDate) : null}
                              onChange={(e) => {
                                handleInputChange(
                                  "startDate",
                                  moment(e).format(BACKEND_DATE_FORMAT)
                                );
                              }}
                              onError={(err) => {
                                handleDateError(err,'startDate')
                              }}
                              slotProps={{inputAdornment:{position:"start"},
                              textField:{helperText:errors?.startDate?.message,"data-testid":"start-date",
                              placeholder:START_DATE,name:"date",id:"start-date"}}}
                            />
                          </LocalizationProvider>
                        </div>}
                      </GridColumn>
                      <GridColumn sm={1}>
                        <p className={classes.textAlignCenter}>to</p>
                      </GridColumn>
                      <GridColumn sm={5}>
                      {loading || dataLoading ?
                    <Skeleton height="58px" variant="rectangle" />
                    :
                        <div className={classes.contetSpacing8} >
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              sx={{width:"100%"}}
                              id="end-date-wrap"
                              required={true}
                              value={data?.endDate ? moment(data?.endDate) : null}
                              onChange={(e) => {
                                handleInputChange(
                                  "endDate",
                                  moment(e).format(BACKEND_DATE_FORMAT)
                                );
                              }}
                              onError={(err) => {
                                handleDateError(err,'endDate')
                              }}
                              slotProps={{inputAdornment:{position:"start"},
                              textField:{"data-testid":"end-date",
                              placeholder:END_DATE,name:"date",id:"end-date"}}}
                            />
                          </LocalizationProvider>
                        {errors?.endDate && <MuiValidationMessage message={errors?.endDate?.message}/>}
                        </div>
                        }
                      </GridColumn>
                    </Grid>
                  </div>
                ))}
              </div>
              <div
                className={[classes.columnSpacing, classes.divBorder].join(" ")}
                data-testid="budgetType"
              >
                <Heading as="h3" size="large" className={classes.subTitle}>
                  Budget Type*
                </Heading>
                <div className={classes.flexGrid}>
                {loading || dataLoading ?
                  <Skeleton height="58px" variant="rectangle" />
                  :
                  <FormGroup>
                    <Radio
                      label="Total"
                      name="budgetType"
                      checked={data?.budgetType?.toLowerCase() === "total"}
                      value="Total"
                      data-testid="budget-total-radio"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                      disabled={mode === "edit"}
                    />
                    <Radio
                      label="Phase"
                      id="budgetTypePhase"
                      name="budgetType"
                      checked={data?.budgetType?.toLowerCase() === "phase"}
                      data-testid="budget-phase-radio"
                      value="Phase"
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                      disabled={mode === "edit"}
                    />
                  </FormGroup>
                  }

                  {data?.budgetType?.toLowerCase() === "total" && (
                    <div
                      className={classes.budgetTypeTotal}
                      data-testid="budget-total"
                    >
                      <Grid>
                        <GridColumn sm={12}>
                          <Label text={BUDGET_AMOUNT} mandatoryField/>
                          {loading || dataLoading ?
                          <Skeleton height="58px" variant="rectangle" />
                          :
                          <MuiTextField
                            id="amount-wrap"
                            value={data?.budgetAmount}
                            data-testid="budget-input-amount"
                            pattern="[0,9]*"
                            helperText={errors?.budgetAmount && <MuiValidationMessage  message={errors?.budgetAmount?.message}/>}
                            inputProps={{
                              id: "budgetAmount",
                              name: "budgetAmount"
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><span>$</span></InputAdornment>,
                              endAdornment: <InputAdornment position="end"><span>USD</span></InputAdornment>,
                            }}
                            leadingIcon={<span>$</span>}
                            trailing={"USD"}
                            type="number"
                            onKeyDown={(e) => onInputNumberKeyDown(e)}
                            fullWidth={true}
                            {...register(
                              'budgetAmount',
                              {...budgetDetailValidationRules?.budgetAmount, onChange: (e) => {handleInputChange(e.target.name, e.target.value)}}
                            )}
                            error={errors?.budgetAmount}
                          />
                          }
                        </GridColumn>
                      </Grid>
                    </div>
                  )}

                  {data?.budgetType?.toLowerCase() === "phase" && (
                    <div
                      className={classes.budgetTypePhase}
                      data-testid="budget-phase"
                    >
                      {loading || dataLoading ?
                      <Skeleton height="58px" variant="rectangle" />
                      : <>
                      <div className={classes.addPhase}>
                        <div
                          className={classes.cursorPointer}
                          data-testid = "budget_phase_addPhase"
                          id="budget_phase_addPhase"
                          onClick={handleAddPhase}
                        >
                          <Plus size="medium" />
                          <span className={classes.addNewText}>Add Phase</span>
                        </div>
                      </div>

                      <Grid style={{ flex: 1 }}>
                        <div className={classes.overviewTableHeader}>
                          <GridColumn
                            className={classes.overviewTableHeaderRow}
                            sm={2}
                          >
                            Code
                          </GridColumn>
                          <GridColumn
                            className={classes.overviewTableHeaderRow}
                            sm={7}
                          >
                            {DESCRIPTION}
                          </GridColumn>
                          <GridColumn
                            className={classes.overviewHeaderRowRight}
                            sm={2}
                          >
                            $ Budget Amount
                          </GridColumn>
                          <GridColumn
                            className={classes.overviewHeaderRowRight}
                            sm={1}
                          ></GridColumn>
                        </div>
                        {data?.budgetPhases ? data?.budgetPhases?.map((item) => {
                          const showError = phaseItemsError?.find(x => x?.name === 'budgetAmount' && x?.rowIndex === item?.id);
                          return( <div className={classes.overviewTableBody} key={item?.id}>
                            <GridColumn
                              className={item?.new ? classes.overviewTableBodyRowEdit : classes.overviewTableBodyRow}
                              sm={2}
                            >
                              {item?.new ?
                                <UtbmsActivityAutocomplete
                                name="code"
                                value={item?.code}
                                setValue={setUtbmsCode}
                                data={masterDetails?[...masterDetails?.expenseCode?.masterData, ...masterDetails?.phaseCode?.masterData, ...masterDetails?.taskCode?.masterData ]:[]}
                                placeholder="Enter Code"
                                handleBlur={() => {}}
                                showDescription={false}
                                codeId={item?.id}
                              />
                               : (
                                item?.code
                              )}
                            </GridColumn>
                            <GridColumn
                              className={item?.new ? classes.overviewTableBodyRowEdit : classes.overviewTableBodyRow}
                              sm={7}
                            >
                              {item?.new ?
                                <MuiTextField
                                  value={item?.displayName}
                                  disabled={true}
                                  data-testid="budget_phase_displayName"
                                  id="displayName"
                                  placeholder="Enter Description"
                                  name= "displayName"
                                  onChange={ (e) => {handleInputChangeBudgetPhase(e.target.name, e.target.value,item?.id)}}
                                  type="text"
                                  fullWidth={true}
                                  className={classes.textFieldTop}
                                />
                               : (
                                item?.displayName
                              )}
                            </GridColumn>
                            <GridColumn
                              className={item?.new ? classes.overviewBodyRowRightEdit : classes.overviewBodyRowRight}
                              sm={2}
                            >
                              {item?.new ?
                                    <MuiTextField
                                      value={item?.budgetAmount}
                                      placeholder="Amount"
                                      data-testid="budget_phase_budgetAmount"
                                      id= "budgetAmountNew"
                                      name= "budgetAmount"
                                      error={showError?.error}
                                      helperText={showError?.error && <MuiValidationMessage  message={'Required'}/>}
                                      onChange={ (e) => {handleInputChangeBudgetPhase(e.target.name, e.target.value,item?.id)}}
                                      onKeyDown={(e) => onInputNumberKeyDown(e)}
                                      type="number"
                                      fullWidth={true}
                                      className={classes.textFieldTop}
                                    />
                                    : (
                                      item["budgetAmount"]
                                    )}
                            </GridColumn>
                            <GridColumn
                              className={classes.overviewTableBodyRowRemove}
                              sm={1}
                            >
                              <Button
                                variant="tertiary"
                                onClick={()=>handleRemoveClick(item?.id)}
                                data-testid={`budget_phase_remove`}
                              >
                                {REMOVE}
                              </Button>
                            </GridColumn>
                          </div>
                        )}): <></>}
                      </Grid>
                      </>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </GridColumn>
          <GridColumn sm={3}>
            <div
              className={[classes.columnSpacing, classes.divBorder].join(" ")}
              data-testid="budgetMatter"
            >
              <Heading as="h3" size="large" className={classes.subTitle}>
                Matter
              </Heading>
              <div>
                <Label text={FIND_MATTER} mandatoryField/>
                {loading || dataLoading ?
                  <Skeleton height="58px" variant="rectangle" />
                  :
                <MatterAutocomplete
                  value={data?.matter}
                  required={true}
                  setValue={(value) => {
                    handleInputChange("matter", value)
                  }}
                  disabled={fromMatter || mode === "edit"}
                  register={register}
                  errors={errors}
                  name={"matter"}
                  validationRules={budgetDetailValidationRules}
                  isRestricted = {false}
                />
                }
              </div>
            </div>
          </GridColumn>
        </Grid>
      </Detail>
    </div>
  );
};

export default CreateEditBudget;
