import {
  Divider,
  Grid,
  GridColumn, Heading, Skeleton,
  Switch, TextArea,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getService, patchService } from "../../../axios/axios";
import Label from "../../../components/TextField/Label/Label";
import { USER_REQUEST_SERVICE } from "../../../constants/baseURLs";
import {
  AddressTypes,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_TYPE,
  APPROVAL_DATA, BILLING_CONTACT, CITY, CONTACT_INFO, COUNTRY, 
  detailModes as modes, DETAILS, CLASSIFICATION, START_DATE, TKID,
  EDIT_TIMEKEEPER_TITLE, EMAIL, END_DATE, FIRST_NAME, JD_YEAR,
  JUSTIFICATION_FOR_TIMEKEEPER, LAST_NAME, ORGANIZATION, PHONE_NUMBER,
  REQUESTER_EMAIL, ROLE, ROLE_VALUES,
  SELECT_SUBJECT_MATTER_EXPERTISE,
  SELECT_TYPE, START_END_DATE_VALIDATION_MESSAGE, STATE, STATE_BAR_LICENCE,
  SUBJECT_MATTER_EXPERTISE, WALMART_APPOVING_ATTORNEY, SELECT_STATE, SELECT_COUNTRY, SELECT_STATE_BAR_LICENSE, SELECT_CLASSIFICATION, CS_IS_LEAD_ATTORNEY_FILTER, EDIT_ADMIN_TITLE, CREATED_DATE} from "../../../constants/constants";
import { USER_DETAILS_UPDATE_MSG } from "../../../constants/messages";
import { resetTimeKeeperAdminValue, setInputValue, setTimekeeperDetail } from "../../../store/actions/timeKeeperAdminActions";
import Detail from "../../Detail/Detail";
import EditTimeKeeperProfileStyles from "./EditTimeKeeperProfile.styles";
import PeopleAutocomplete from "../../../components/Autocomplete/PeopleAutocomplete/PeopleAutocomplete";
import { SUBJECT_MATTER_EXPERTISE_MSG } from "../../../Validation/validationMessages";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { timekeeperValidationRules } from "../../../Validation/validationRules";
import { useForm } from "react-hook-form";
import { BACKEND_DATE_FORMAT, MOMENT_DATE_FORMAT, DATE_DD_MMM_YYYY, DATE_END_TIME } from "../../../constants/dateFormats";
import { getErrorMessage, renderTernary } from "../../../helpers/utils";
import { filterPayload, formatPhoneNumber, phoneNumberForBackend } from "../../../helpers/commonUtils";
import MasterDataAutocomplete from "../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import MasterDataMultiAutocomplete from "../../../components/Autocomplete/MasterDataMultiAutocomplete/MasterDataMultiAutocomplete";
import { convertToUTCDateTime } from "../../../helpers/utcUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MenuItem, Select, TextField } from "@mui/material";

const CHARACTER_LIMIT = 100;


const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};
/**
 * This component is used to only edit timekeeper profile.
 */
const EditTimeKeeperProfile = () => {
  const classes = EditTimeKeeperProfileStyles()
  const dispatch = useDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const { pathname} = useLocation()
  const [selectedRole] = useState(
    pathname?.includes("timekeeper") ? "Timekeeper" : "Admin"
  )
  const { peopleId } = useParams();
  const [data, setData] = useState([]);
  const {
    firstName,
    lastName,
    emailId,
    primaryOfficeLocation,
    starBarLicense,
    subjectMatterExpertise,
    justificationForRole,
    tkId,
    classification,
    billingContact,
    organizationName,
    phoneNumber,
    jdYear,
    walmartApprovingAttorneyFullName,
    startDate,
    addressType,
    addressLine1,
    addressLine2,
    stateBarLicence,
    endDate,
    city,
    state,
    country,
    walmartApprovingAttorneyEmailId,
    walmartApprovingAttorneyFirstName,
    walmartApprovingAttorneyLastName,
    walmartApprovingAttorneyUserId,
    requesterEmailId,
    isTimeKeeperRoleEnabled
  } = useSelector((state) => state?.timeKeeperAdmin?.timeKeeperAdminValues);
  const { addSnack } = useSnackbar();
  const { register, formState: { errors }, setError, clearErrors } = useForm({ mode: "all" });
  const [walmartTimekeeperAttorney, setWalmartTimekeeperAttorney] = useState(walmartApprovingAttorneyFirstName ? {
    email: walmartApprovingAttorneyEmailId,
    firstName: walmartApprovingAttorneyFirstName,
    lastName: walmartApprovingAttorneyLastName,
    userId: walmartApprovingAttorneyUserId,
  } : '');
  const [editValues, setEditValues] = useState({});

  useEffect(() => {
    setLoading(true);
    getTimekeeperData();
    return(()=>{
      dispatch(resetTimeKeeperAdminValue())
    })
  }, []);

  /**
   * This function is called when api call throws an error
   */
   const handleError = (error) => {
    setLoading(false);
      addSnack({
        message: getErrorMessage(error),
      });
  }

  /*
  * Function to get the existing data-Before Update
  */
  const getTimekeeperData = () => {
    getService( USER_REQUEST_SERVICE, `/people/v1/${peopleId}`)
      .then((res) => {
        setLoading(false);
        const payload = res?.data?.result?.people;
        dispatch(setTimekeeperDetail(payload));
        setData(res?.data?.result?.people);
      })
      .catch((error) => {
        handleError(error)
      });
  }

  /**
   * Gets called when data changes in input fields.
   * @param {object} e - input event
   */
  const onInputChange = (name, value) => {
    dispatch(setInputValue(name, value));
    setEditValues({...editValues,[name]:value})
  };

  /**
   * This function will use to handle the multiple subject matter expertise
  */
  const multiSelectHandler = (e, value) => {
    dispatch(setInputValue('subjectMatterExpertise', value?.toString()));
    setEditValues({...editValues,'subjectMatterExpertise':value?.toString()})
  }

  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '56px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };

  /**
   * Gets called when clicked on update button-calls patch Service.
   */
  const handleUpdate = () => {
    const body = {
      firstName:editValues?.firstName,
      lastName:editValues?.lastName,
      emailId:editValues?.emailId,
      primaryOfficeLocation:editValues?.primaryOfficeLocation,
      stateBarLicence:editValues?.stateBarLicence,
      subjectMatterExpertise:editValues?.subjectMatterExpertise,
      justificationForRole:editValues?.justificationForRole,
      tkId:editValues?.tkId,
      classification:editValues?.classification,
      billingContact:editValues?.billingContact,
      organizationName:editValues?.organizationName,
      phoneNumber: phoneNumberForBackend(editValues?.phoneNumber),
      role:editValues?.role,
      jdYear:editValues?.jdYear,
      endDate: (editValues?.endDate && editValues?.endDate !== "Invalid date") ? convertToUTCDateTime({date:editValues?.endDate, time: DATE_END_TIME}): '',
      walmartApprovingAttorneyEmailId:editValues?.walmartApprovingAttorneyEmailId,
      walmartApprovingAttorneyFirstName:editValues?.walmartApprovingAttorneyFirstName,
      walmartApprovingAttorneyFullName:editValues?.walmartApprovingAttorneyFullName,
      walmartApprovingAttorneyLastName:editValues?.walmartApprovingAttorneyLastName,
      walmartApprovingAttorneyUserId:editValues?.walmartApprovingAttorneyUserId,
      addressType: editValues?.addressType,
      addressLine1: editValues?.addressLine1,
      addressLine2: editValues?.addressLine2,
      city: editValues?.city,
      state: editValues?.state,
      country: editValues?.country,
    }
    const filteredBody=filterPayload(body)
    patchService(USER_REQUEST_SERVICE, `/people/v1/${peopleId}`, filteredBody)
      .then((res) => {
        setLoading(false);
        addSnack({
          message: USER_DETAILS_UPDATE_MSG,
        });
        history.push(`/people/${peopleId}/view`)
      })
      .catch((error) => {
        handleError(error)
      });
  }
  /**
   * Gets called when clicked on cancel button.
   */
  const handleCancel = () => {
    history.push(`/people/${peopleId}/view`)
  }


  /** Function will use for handle walmart attorney dropdown */
  const handleWalmartAttorney = (value) =>{
    setWalmartTimekeeperAttorney(value);
  }

  useEffect(()=>{
    dispatch(setInputValue('walmartApprovingAttorneyEmailId', (walmartTimekeeperAttorney?.email ? walmartTimekeeperAttorney?.email : '')));
    dispatch(setInputValue('walmartApprovingAttorneyFirstName',walmartTimekeeperAttorney?.firstName));
    dispatch(setInputValue('walmartApprovingAttorneyFullName',`${walmartTimekeeperAttorney?.firstName} ${walmartTimekeeperAttorney?.lastName}`));
    dispatch(setInputValue('walmartApprovingAttorneyLastName',walmartTimekeeperAttorney?.lastName));
    dispatch(setInputValue('walmartApprovingAttorneyUserId',walmartTimekeeperAttorney?.userId));
    if(walmartTimekeeperAttorney?.firstName !== walmartApprovingAttorneyFullName)//update editValues only when there is a change
    {  setEditValues({...editValues,['walmartApprovingAttorneyEmailId']:walmartTimekeeperAttorney?.email,
      ['walmartApprovingAttorneyFirstName']:walmartTimekeeperAttorney?.firstName,
      ['walmartApprovingAttorneyFullName']:`${walmartTimekeeperAttorney?.firstName} ${walmartTimekeeperAttorney?.lastName}`,
      ['walmartApprovingAttorneyLastName']:walmartTimekeeperAttorney?.lastName,
      ['walmartApprovingAttorneyUserId']:walmartTimekeeperAttorney?.userId,})
    }
  },[walmartTimekeeperAttorney])

  useEffect(()=>{
    { setWalmartTimekeeperAttorney(walmartApprovingAttorneyFirstName ? {
      email: walmartApprovingAttorneyEmailId,
      firstName: walmartApprovingAttorneyFirstName,
      lastName: walmartApprovingAttorneyLastName,
      userId: walmartApprovingAttorneyUserId,
    }:'')}
  },[walmartApprovingAttorneyFirstName])

  useEffect(() => {
    if (moment(new Date(endDate)).format(DATE_DD_MMM_YYYY) < moment(startDate).format(DATE_DD_MMM_YYYY)) {
      setError('endDate', { type: 'custom', message: START_END_DATE_VALIDATION_MESSAGE });
    }
    else {
      clearErrors('endDate')
    }
  }, [endDate])

  useEffect(() => {
    if (subjectMatterExpertise?.length === 0) {
      setError('subjectMatterExpertise', { type: 'custom', message: SUBJECT_MATTER_EXPERTISE_MSG });
    }
    else {
      clearErrors('subjectMatterExpertise')
    }
  }, [subjectMatterExpertise])

  /*
  *Variable to track field changes
  */
  let isDirty = false;
  isDirty = ((firstName !== "" && lastName !== "" && emailId !== "" && addressType !== "" && addressLine1 !== "") && (
    firstName !== data?.firstName || lastName !== data?.lastName ||
    emailId !== data?.emailId || primaryOfficeLocation !== data?.primaryOfficeLocation ||
    starBarLicense !== data?.starBarLicense || subjectMatterExpertise !== data?.subjectMatterExpertise ||
    justificationForRole !== data?.justificationForRole || tkId !== data?.tkId ||
    classification !== data?.classification || billingContact !== data?.billingContact ||
    organizationName !== data?.organizationName || phoneNumber !== data?.phoneNumber ||
    selectedRole !== data?.selectedRole || jdYear !== data?.jdYear ||
    walmartApprovingAttorneyFullName !== data?.walmartApprovingAttorneyFullName || endDate !== data?.endDate ||
    startDate !== data?.startDate || endDate !== data?.endDate ||
    addressType !== data?.addressType || addressLine1 !== data?.addressLine1 ||
    addressLine2 !== data?.addressLine2 || city !== data?.city ||
    state !== data?.state || country !== data?.country
  ))

  const TimeKeeperForm = <>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{APPROVAL_DATA}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={STATE_BAR_LICENCE} />
              <MasterDataAutocomplete
              value={stateBarLicence}
              setValue={(value)=>{onInputChange("stateBarLicence", value?.value)}}
              dataType={"stateBarLicense"}
              testid="edittimekeeperprofile-starBarLicense"
              id="edittimekeeperprofile-starBarLicense"
              required = {true}
              register={register}
              errors={errors}
              name={"stateBarLicence"}
              validationRules = {timekeeperValidationRules}
              placeholderValue={SELECT_STATE_BAR_LICENSE}
              />
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <div className={classes.labelStyle}><Label text={SUBJECT_MATTER_EXPERTISE} mandatoryField={true}/></div>
              <MasterDataMultiAutocomplete
                dataType={"subjectMatterExpertise"}
                value={subjectMatterExpertise?.length > 0 ? subjectMatterExpertise?.split(','): []}
                placeholderValue={SELECT_SUBJECT_MATTER_EXPERTISE}
                setValue={multiSelectHandler}
                testid="create-timekeeper-subject-matter-expertise"
                id="create-timekeeper-subject-matter-expertise"
              />
              <div className={classes.errorMsg}>{errors?.subjectMatterExpertise && <MuiValidationMessage message={errors?.subjectMatterExpertise.message} />}</div>
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={JUSTIFICATION_FOR_TIMEKEEPER} />
              <TextArea
                error={errors?.justificationForRole && <MuiValidationMessage message={errors?.justificationForRole?.message} mode="LD" />}
                value={justificationForRole}
                maxLength={CHARACTER_LIMIT}
                textAreaProps={{
                  name: "justificationForRole",
                }}
                data-testid="edittimekeeperprofile-justificationfortimekeeper"
                {...register(
                  'justificationForRole',
                  { ...timekeeperValidationRules?.justificationForTimeKeeper, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
              />
            </React.Fragment>
          })}
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.approvalDataRightSection}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={JD_YEAR} />
            <TextField
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={jdYear}
              name= "jdYear"
              type="text"
              data-testid="edittimekeeperprofile-jdyear"
              fullWidth={true}
            />
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing16}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={WALMART_APPOVING_ATTORNEY} />
            <PeopleAutocomplete
              value={walmartTimekeeperAttorney}
              required={true}
              setValue={(value) => {
                handleWalmartAttorney(value);
              }}
              placeholderValue={'Select Walmart Approving Attorney'}
              filterCheck={false}
              testid="edittimekeeperprofile-walmart-approving-attroney"
              register={register}
              errors={errors}
              name={"walmartTimekeeperAttorney"}
              validationRules={timekeeperValidationRules}
              additionalQuery={CS_IS_LEAD_ATTORNEY_FILTER}
            />
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}><Label text={REQUESTER_EMAIL}/></div>
      <div className={classes.contentSpacing16}>
        {requesterEmailId}
      </div>
    </GridColumn>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{DETAILS}</h2>
        </div>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={TKID} />
            <TextField
              error={errors?.tkId}
              helperText={errors?.tkId && <MuiValidationMessage message={errors?.tkId?.message}/>}
              value={tkId}
              name= "tkId"
              type="text"
              data-testid="edittimekeeperprofile-tkid"
              {...register(
                'tkId',
                { ...timekeeperValidationRules?.tkId, onChange: (e) => onInputChange(e.target.name, e.target.value) }
              )}
              fullWidth={true}
            />
          </React.Fragment>
        })}
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={CLASSIFICATION} />
              <MasterDataAutocomplete
              value={classification}
              setValue={(value)=>{onInputChange("classification", value?.value)}}
              dataType={"classification"}
              testid="edittimekeeperprofile-classification"
              placeholderValue={SELECT_CLASSIFICATION}
              required={true}
              register={register}
              errors={errors}
              validationRules = {timekeeperValidationRules}
              name={"classification"}
              />
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
          <Switch
            isOn={billingContact}
            label={<span>{BILLING_CONTACT}</span>}
            onClick={() => {
              onInputChange("billingContact", !billingContact);
            }}
            data-testid="edittimekeeperprofile-billingContact"
            name="billingContact"
          />
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.createdDate}>
        <Label text={CREATED_DATE} />
      </div>
      <div className={classes.contentSpacing16}>{moment(Date.now()).format(MOMENT_DATE_FORMAT)}</div>
      <div className={classes.contentSpacing42}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={START_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                disabled={true}
                value={renderTernary(startDate, moment.utc(startDate).local(), null)}
                onChange={(e) => {
                  onInputChange(
                    'startDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"startDate","data-testid":"edittimekeeperprofile-startDate"}}}
              />
            </LocalizationProvider>
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={END_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                value={renderTernary(endDate, moment.utc(endDate).local(), null)}
                onChange={(e) => {
                  onInputChange(
                    'endDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"endDate","data-testid" : "edittimekeeperprofile-enddate"}}}
              />
            </LocalizationProvider>
            <div className={classes.errorMsg}>{errors?.endDate && <MuiValidationMessage message={errors?.endDate.message} />}</div>
          </React.Fragment>
        })}
      </div>
    </GridColumn>
  </>;

  const AdminForm = <>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{APPROVAL_DATA}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={JUSTIFICATION_FOR_TIMEKEEPER} />
              <TextArea
                error={errors?.justificationForRole && <MuiValidationMessage message={errors?.justificationForRole?.message} mode="LD" />}
                value={justificationForRole}
                maxLength={CHARACTER_LIMIT}
                textAreaProps={{
                  name: "justificationForRole",
                }}
                data-testid="edittimekeeperprofile-justificationfortimekeeper"
                {...register(
                  'justificationForRole',
                  { ...timekeeperValidationRules?.justificationForTimeKeeper, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
              />
            </React.Fragment>
          })}
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.contentSpacing16}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={WALMART_APPOVING_ATTORNEY} />
            <PeopleAutocomplete
              value={walmartTimekeeperAttorney}
              required={true}
              setValue={(value) => {
                handleWalmartAttorney(value);
              }}
              placeholderValue={'Select Walmart Approving Attorney'}
              filterCheck={false}
              testid="edittimekeeperprofile-walmart-approving-attroney"
              register={register}
              errors={errors}
              name={"walmartTimekeeperAttorney"}
              validationRules={timekeeperValidationRules}
              additionalQuery={CS_IS_LEAD_ATTORNEY_FILTER}
            />
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}><Label text={REQUESTER_EMAIL}/></div>
      <div className={classes.contentSpacing16}>
        {requesterEmailId}
      </div>
    </GridColumn>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{DETAILS}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          <Switch
            isOn={billingContact}
            label={<span>{BILLING_CONTACT}</span>}
            onClick={() => {
              onInputChange("billingContact", !billingContact);
            }}
            data-testid="edittimekeeperprofile-billingContact"
            name="billingContact"
          />
        </div>
        <div className={classes.createdDate}>
          <Label text={CREATED_DATE} />
        </div>
        <div className={classes.contentSpacing16}>{moment(Date.now()).format(MOMENT_DATE_FORMAT)}</div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.contentSpacing42}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={START_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                disabled={true}
                value={renderTernary(startDate, moment.utc(startDate).local(), null)}
                onChange={(e) => {
                  onInputChange(
                    'startDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"startDate","data-testid":"edittimekeeperprofile-startDate"}}}
              />
            </LocalizationProvider>
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={END_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                value={renderTernary(endDate, moment.utc(endDate).local(), null)}
                onChange={(e) => {
                  onInputChange(
                    'endDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"endDate","data-testid" : "edittimekeeperprofile-enddate"}}}
              />
            </LocalizationProvider>
            <div className={classes.errorMsg}>{errors?.endDate && <MuiValidationMessage message={errors?.endDate.message} />}</div>
          </React.Fragment>
        })}
      </div>
    </GridColumn>
  </>
  
  let isTimeKeeperForm;
  let title
  if (!loading) {
    if (isTimeKeeperRoleEnabled) {
      isTimeKeeperForm = TimeKeeperForm
      title = EDIT_TIMEKEEPER_TITLE
    } else {
      isTimeKeeperForm = AdminForm
      title = EDIT_ADMIN_TITLE
    }
  }

  return (
    <Detail
      mode={modes.EDIT}
      setMode={() => { }}
      breadcrumbValues={[]}
      onUpdate={handleUpdate}
      onCancel={handleCancel}
      loading={loading}
      isDirty={isDirty}
      isValid={true}
      hideDeleteBtn
    >
      <div className={classes.leftContent}>
        <Heading as="h1" size="large">
          {title}
        </Heading>
      </div>
      <Grid>
        <GridColumn sm={9}>
          <div className={classes.leftContent}>
            <h2>{CONTACT_INFO}</h2>
            <Grid className={classes.grid}>
              <GridColumn lg={6}>
                {LoadField({
                  ...loadFieldProps, node: <React.Fragment>
                    <Label text={FIRST_NAME} mandatoryField={true} />
                    <TextField
                      error={errors?.firstName}
                      helperText={errors?.firstName && <MuiValidationMessage message={errors?.firstName?.message}/>}
                      value={firstName}
                      name= "firstName"
                      type="text"
                      data-testid="edittimekeeperprofile-firstname"
                      id="edittimekeeperprofile-firstname"
                      {...register(
												'firstName',
												{ ...timekeeperValidationRules?.firstName, onChange: (e) => onInputChange(e.target.name, e.target.value) }
											)}
                      fullWidth={true}
                    />
                  </React.Fragment>
                })}
              </GridColumn>
              <GridColumn lg={5}>
                {LoadField({
                  ...loadFieldProps, node: <React.Fragment>
                    <Label text={LAST_NAME} mandatoryField={true} />
                    <TextField
                      error={errors?.lastName}
                      helperText={errors?.lastName && <MuiValidationMessage message={errors?.lastName?.message}/>}
                      value={lastName}
                      name= "lastName"
                      type="text"
                      data-testid="edittimekeeperprofile-lastname"
                      id="edittimekeeperprofile-lastname"
                      {...register(
												'lastName',
												{ ...timekeeperValidationRules?.lastName, onChange: (e) => onInputChange(e.target.name, e.target.value) }
											)}
                      fullWidth={true}
                    />
                  </React.Fragment>
                })}
              </GridColumn>
            </Grid>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <React.Fragment>
                  <Label text={EMAIL} mandatoryField={true} />
                  <TextField
                    error={errors?.email}
                    helperText={errors?.email && <MuiValidationMessage message={errors?.email?.message}/>}
                    value={emailId}
                    name= "emailId"
                    id= "emailId"
                    type="text"
                    disabled={true}
                    data-testid="edittimekeeperprofile-email"
                    {...register(
                      'emailId',
                      { ...timekeeperValidationRules?.email, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                    )}
                    fullWidth={true}
                  />
                </React.Fragment>
              })}
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: 
                <Grid className={classes.grid}>
                <GridColumn sm={6}>
                  <Label text={ROLE} mandatoryField={true}/>
                  <Select
                  value={selectedRole}
                  disabled
                  name= "role"
                  data-testid="edittimekeeperprofile-role"
                  fullWidth={true}
                >
                  <MenuItem value="">{"Select Role"}</MenuItem>
                  {ROLE_VALUES?.map((value, index) => {
                    return (
                      <MenuItem
                        value={value}
                        key={`${value}`}
                        data-testid="edit-timekeeper-role-option"
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                </GridColumn>
                <GridColumn sm={5}>
                  <Label text={ORGANIZATION} mandatoryField={true}/>
                  <text className={classes.companyNameStyle}>{organizationName}</text>
                </GridColumn>
                </Grid>
              })}
            </div>
            <div className={classes.contentSpacing16}>
            {LoadField({
                ...loadFieldProps, node: <React.Fragment>
                <Label text={PHONE_NUMBER} />
                <TextField
                  error={errors?.phoneNo}
                  helperText={errors?.phoneNo && <MuiValidationMessage message={errors?.phoneNo?.message}/>}
                  value={formatPhoneNumber(phoneNumber)}
                  name= "phoneNumber"
                  id= "phoneNumber"
                  type="tel"
                  data-testid="edittimekeeperprofile-phoneno"
                  {...register(
                    'phoneNumber',
                    { ...timekeeperValidationRules?.phoneNo, onChange: (e) => onInputChange(e.target.name, formatPhoneNumber(e.target.value)) }
                  )}
                  fullWidth={true}
                />
              </React.Fragment>
            })}
          </div>
          </div>
        </GridColumn>
        <GridColumn sm={3} className={classes.gridColumn}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={ADDRESS_TYPE} mandatoryField={true} />
              <Select
                error={errors?.addressType}
                value={addressType}
                name= "addressType"
                data-testid="edit-timekeeper-addressType"
                id="edit-timekeeper-addressType"
                {...register(
                  'addressType',
                  { ...timekeeperValidationRules?.addressType, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
                fullWidth={true}
              >
                <MenuItem value="">{SELECT_TYPE}</MenuItem>
                {AddressTypes?.map((value, index) => {
                  return (
                    <MenuItem
                      value={value}
                      key={`${value}`}
                      id={`create-timekeeper-addressType-option-${index}`}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <div className={classes.errorMsg}>{errors?.addressType && <MuiValidationMessage message={errors?.addressType?.message} />}</div>
            </React.Fragment>
          })}
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={ADDRESS_LINE_1} mandatoryField={true} />
                <TextField
                error={errors?.addressLine1}
                helperText={errors?.addressLine1 && <MuiValidationMessage message={errors?.addressLine1?.message}/>}
                value={addressLine1}
                name= "addressLine1"
                type="text"
                data-testid="edit-timekeeper-addressLine1"
                {...register(
                  'addressLine1',
                  { ...timekeeperValidationRules?.addressLine1, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
                fullWidth={true}
              />
              </React.Fragment>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={ADDRESS_LINE_2} />
                <TextField
                  onChange={(e) => {
                    onInputChange(e.target.name, e.target.value);
                  }}
                  value={addressLine2}
                  name= "addressLine2"
                  id= "addressLine2"
                  type="text"
                  data-testid="edit-timekeeper-addressLine2"
                  fullWidth={true}
              />
              </React.Fragment>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <Grid className={classes.grid}>
              <GridColumn sm={6}>
                <Label text={CITY} />
                <TextField
                  onChange={(e) => {
                    onInputChange(e.target.name, e.target.value);
                  }}
                  value={city}
                  name= "city"
                  type="text"
                  data-testid="edit-timekeeper-city"
                  fullWidth={true}
              />
              </GridColumn>
              <GridColumn sm={5}>
                <Label text={STATE} />
                <MasterDataAutocomplete
                  value={state}
                  setValue={(value)=>{onInputChange("state", value?.value)}}
                  dataType={"state"}
                  name={"state"}
                  testid="edit-timekeeper-state"
                  placeholderValue={SELECT_STATE}
                  />
                </GridColumn>
              </Grid>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={COUNTRY} />
                <MasterDataAutocomplete
                  value={country}
                  setValue={(value)=>{onInputChange("country", value?.value)}}
                  dataType={"country"}
                  name={"country"}
                  testid="edit-timekeeper-country"
                  placeholderValue={SELECT_COUNTRY}
                  />
              </React.Fragment>
            })}
          </div>
        </GridColumn>
        {isTimeKeeperForm}
      </Grid>
    </Detail>
  );
};

export default EditTimeKeeperProfile;
