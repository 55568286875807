import React, { useEffect, useMemo, useState } from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from '@mui/material/utils';
import { CS_SCOPES, SELECT_MATTER, CS_TYPEAHEAD_PAGINATION_QUERY_FOR_MATTER, MATTER_AUTOCOMPLETE_DEFAULT_SORT_QUERY } from "../../../constants/constants";
import { setMatterDropDownData } from "../../../store/actions/masterDataAction";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import { postService } from "../../../axios/axios";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { getErrorMessage, getHighlightedText, getSensitiveMatterPayload, useUserAssociatedRelation } from "../../../helpers/utils";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { formCompositeSearchQuery, formCsFilterObject, formCsMatterAutocompleteQuery } from "../../../helpers/csQueryUtils";
import { matterDataMapping } from "../../../helpers/tableUtils";
import { createUseStyles } from "react-jss";

const createAutoStyles = createUseStyles({
	displayBlock: {
		display: "block"
	},
	boxLi: {
		overflow: "hidden",
		cursor: "pointer",
		paddingTop: "6px",
		boxSizing: "border-box",
		outline: 0,
		paddingBottom: "6px",
		paddingLeft: "16px",
		paddingRight: "16px",
		'&:hover' : {
			background: "rgba(0, 0, 0, 0.04)"
		}
	}
})


const MatterAutocomplete = (props) => {
	const { value, setValue, required = false, disabled = false, id = "matter-autocomplete", register, errors, name = "matterAutocomplete", validationRules, isRestricted = true } = props;
	const { addSnack } = useSnackbar();
	const classes = createAutoStyles();
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false);
	const { matterDropDownData } = useSelector((state) => state?.masterData);
	const [loading, setLoading] = useState()
	const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
	const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
	const filterOptions = createFilterOptions({
		stringify: (option) => `${option?.matterName} (${option?.referenceKey}) (${option?.matterNumber})`,
	});
	const [inputValue, setInputValue] = useState('');
	const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
	const relations = useUserAssociatedRelation(matterAccessibility);

	/**
	*Function to call Composite Search Service for matter
	*/
	const getMattersData = (searchQuery = {}) => {
		setLoading(true);
		const isActiveFilter = formCsFilterObject('isActive', 'match', true);
		const orgIdFilter = formCsFilterObject('organizations.id', 'match', orgIdentifier);
		const defaultQuery = {
			"filters": [isActiveFilter,orgIdFilter],
			"operation": "AND",
			"properties": null
		}
		const userRelation = {
			"filters": relations,
			"operation": "AND",
			"properties": null
		}
    	const sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
		const query = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, MATTER_AUTOCOMPLETE_DEFAULT_SORT_QUERY, CS_TYPEAHEAD_PAGINATION_QUERY_FOR_MATTER, {}, defaultQuery, searchQuery, userRelation, sensitiveMatterQuery);

		postService(
			COMPOSITE_SEARCH_SERVICE,
			`/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}${isRestricted ? '&options=restrictMode':''}`,
			query
		)
			.then((response) => {
				const matterResponse = matterDataMapping(response?.data?.matter);
				dispatch(setMatterDropDownData({ count: matterResponse?.count, nodeList: matterResponse?.matters }))
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				addSnack({
					message: getErrorMessage(error)
				});
			});
	}

	 // Debounce logic to form search query
	 const fetch = useMemo(() =>
	 debounce((searchText, callback) => {
		 const searchQueryObj = formCsMatterAutocompleteQuery(searchText);
		 if(searchText?.length > 2) {
			 getMattersData(searchQueryObj)
		 }
	 }, 600),
	 [],
 );

 useEffect(() => {
	 if(inputValue?.length < 1) {
		 dispatch(setMatterDropDownData([]))
		 setLoading(false)
	 }
 },[inputValue])

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			autoHighlight
			autoComplete
			noOptionsText={inputValue?.length < 1 ? "Please type to search" : "No options" }
			loading={loading}
			options={matterDropDownData?.nodeList ?? []}
			getOptionLabel={(option) => `${option?.matterName} (${option?.referenceKey ?? option?.matterNumber})`}
			renderOption={(props, option) => (
				<li {...props} className={classes.boxLi}>
					{ getHighlightedText(`${option?.matterName} (${option?.matterNumber})`,inputValue) }
				</li>
			)}
			filterOptions={filterOptions}
			id={id}
			value={value}
			disabled={disabled}
			fullWidth={true}
			onInputChange={(event, newInputValue) => {
				if(!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
			onChange={(e, v) => {
				setValue(v?.matterNumber ? {
					referenceKey: v?.matterNumber,
					matterId: v?.identifier,
					matterName: v?.matterName,
					practiceAreaCode: v?.practiceAreaCode
				} : null);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params?.inputProps, required, style: {
							color: '#2E2F32',
							fontFamily: 'Bogle',
							fontSize: 16
						}
					}}
					name= "matterAutocomplete"
					error={errors && errors[name]}
					helperText={(errors && errors[name]) && <MuiValidationMessage message={errors && errors[name]?.message} />}
					{
					...required ? {
						...register(
							name,
							{
								...validationRules[name], onChange: (v) => {
									setValue(v?.matterNumber ? {
										referenceKey: v?.matterNumber,
										matterId: v?.identifier,
										matterName: v?.matterName,
									} : null);
								}
							}
						)
					} : null}
					margin="dense"
					placeholder={SELECT_MATTER}
					variant="outlined"
					data-testid="matter-autocomplete"
					id="matter-autocomplete"
				/>
			)}
		/>
	);
};

export default MatterAutocomplete;
