import types from '../types/userActionTypes';

export const setUserLoginDetails = (payload) => {
  return { type: types.SET_USER_LOGIN_DETAILS, payload }
}

export const setUserLoginTenantId = (payload) => {
  return { type: types.SET_USER_LOGIN_TENANT_ID, payload }
}

// setting logged in user responsibility codes
export const setResponsibilityCodes = (payload) => {
  return { type: types.SET_USER_RESPONSIBILITY_CODES, payload }
}

export const setLogout = () => {
  return { type: types.SET_LOGOUT }
}

// setting static tk
export const setStaticTk = (payload) => {
  return { type: types.SET_STATIC_TK, payload }
}

export const setAuthSign = (payload) => {
  return { type: types.SET_AUTH_SIGN, payload }
}

export const setAuthTkn = (payload) => {
  return { type: types.SET_AUTH_TK, payload }
}

export const setTknDetails = (payload) => {
  return { type: types.SET_TKN_DETAILS, payload }
}

export const setUserRequestId = (payload) => {
  return { type: types.SET_USER_REQUEST_ID, payload }
}

export const setFeatureFlags = (payload) => {
  return { type: types.SET_FEATURE_FLAGS, payload }
}

